import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/users?page=${page}`);

export const create = (data) => authAxios().post(`/users`, data);

export const fetchById = (id) => authAxios().get(`/users/${id}`);

export const deleteById = (id) => authAxios().delete(`/users/${id}`);

export const updateById = (id, data) => authAxios().post(`/users/${id}?_method=PUT`, data);

export const updatePasswordById = (id, data) => authAxios().post(`/users/${id}/password`, data);
