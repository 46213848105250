import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Banners";
import { fetchOptions as fetchShowroomsOptions } from "services/Showrooms";
import { fetchOptions as fetchServiceCentersOptions } from "services/ServiceCenters";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function BannerFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState();
  const [showrooms, setShowrooms] = useState([]);
  const [serviceCenters, setServiceCenters] = useState([]);
  const [, dispatch] = useMaterialUIController();

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.image, setImagePreview);
  }, [values.image]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  useEffect(async () => {
    fetchShowroomsOptions().then(
      (res) => {
        setShowrooms(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    fetchServiceCentersOptions().then(
      (res) => {
        setServiceCenters(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("nameEn", res.data.data.name.en);
          setFieldValue("nameAr", res.data.data.name.ar);
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("subtitleEn", res.data.data.subtitle.en);
          setFieldValue("subtitleAr", res.data.data.subtitle.ar);
          setFieldValue("image", res.data.data.image);
          setFieldValue("link", res.data.data.link);
          setFieldValue("place", res.data.data.place);
          if (res.data.data.showroom) {
            setFieldValue("showroomId", res.data.data.showroom.id);
          }
          if (res.data.data.service_center) {
            setFieldValue("serviceCenterId", res.data.data.service_center.id);
          }
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameEn"
            value={values.nameEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameEn && touched.nameEn}
            helperText={errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameAr"
            value={values.nameAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameAr && touched.nameAr}
            helperText={errors.nameAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("subtitleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="subtitleEn"
            value={values.subtitleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.subtitleEn && touched.subtitleEn}
            helperText={errors.subtitleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("subtitleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="subtitleAr"
            value={values.subtitleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.subtitleAr && touched.subtitleAr}
            helperText={errors.subtitleAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("image")}
          </MDTypography>
          {imagePreview && <MDAvatar src={imagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="image"
            name="image"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "image");
            }}
            onBlur={handleBlur}
            error={errors.image && touched.image}
            helperText={errors.image}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("link")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="link"
            value={values.link}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.link && touched.link}
            helperText={errors.link}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("place")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="place"
            value={values.place}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.place && touched.place}
            helperText={errors.place}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("showroom")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="showroomId"
              name="showroomId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.showroomId}
              error={errors.showroomId && touched.showroomId}
            >
              {showrooms.map((showroom) => (
                <MenuItem key={showroom.value} value={showroom.value}>
                  {showroom.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.showroomId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("serviceCenter")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="serviceCenterId"
              name="serviceCenterId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.serviceCenterId}
              error={errors.serviceCenterId && touched.serviceCenterId}
            >
              {serviceCenters.map((serviceCenter) => (
                <MenuItem key={serviceCenter.value} value={serviceCenter.value}>
                  {serviceCenter.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.serviceCenterId}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default BannerFormik;
