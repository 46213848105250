// Material Dashboard 2 React contexts
import { useMaterialUIController } from "context";
import { translation } from "../util/translation";

export default function useLocale() {
  const [controller] = useMaterialUIController();
  const { direction } = controller;
  /**
   * @param {keyof translation} key
   */
  const locale = (key) => translation[direction === "ltr" ? "en" : "ar"][key];
  return locale;
}
