import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Settings";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function SettingFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();

  useEffect(async () => {
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("code", res.data.data.code);
          setFieldValue("value", res.data.data.value);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("code")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.code && touched.code}
            helperText={errors.code}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("value")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="value"
            value={values.value}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.value && touched.value}
            helperText={errors.value}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default SettingFormik;
