import { useEffect, useState, Fragment } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";
import sidenavLogoLabel from "components/Sidenav/styles/sidenav";
import { fetchOptions as fetchTypesOptions } from "services/VehicleTypes";
import { fetchOptions as fetchConditionsOptions } from "services/VehicleConditions";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { Collapse } from "@mui/material";
import Locales from "components/langSwitcher";
import useLocale from "context/useLocale";

function Sidenav({ brand, brandName, routes, ...rest }) {
  const locale = useLocale();
  const [collapsed, setCollapsed] = useState({});
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, currentUser } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replaceAll("/", "");
  const [types, setTypes] = useState([]);
  const [conditions, setConditions] = useState([]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const setCollapsedByKey = (key, value) => {
    setCollapsed({
      ...collapsed,
      [key]: value,
    });
  };

  const togglecollapsedByKey = (key) => {
    setCollapsedByKey(key, !collapsed[key]);
  };

  useEffect(() => {
    fetchTypesOptions().then((res) => {
      setTypes(res.data.data.data);
    });
    fetchConditionsOptions().then((res) => {
      setConditions(res.data.data.data);
    });
  }, []);

  const initializeCollapsed = (key, collapse, exact, id) => {
    if (typeof collapsed[key] === "undefined") {
      let initialCollapse = false;
      collapse.forEach((element) => {
        const elementKey = id ? `${element.key}-${id}` : element.key;
        const adjustedKeyClear = elementKey.replaceAll("-", "");
        const active = exact
          ? collapseName === adjustedKeyClear
          : collapseName.includes(adjustedKeyClear);
        if (active) {
          initialCollapse = true;
        }
      });
      if (initialCollapse) {
        setCollapsedByKey(key, initialCollapse);
      }
    }
  };

  const renderRoute = (
    {
      type,
      name,
      icon,
      title,
      noCollapse,
      key,
      href,
      route,
      collapse,
      variant,
      replace,
      replaceValue,
      exact,
      isCondition,
      roles,
    },
    mappedTypes,
    mappedConditions
  ) => {
    let returnValue;
    if (
      roles &&
      roles.length > 0 &&
      (!currentUser || !currentUser.roles.some((item) => roles.includes(item)))
    ) {
      return returnValue;
    }
    if (type === "head") {
      if (variant === "type") {
        returnValue = (
          <Fragment key={key}>
            {mappedTypes.map((vehicleType) => {
              const typeKey = `${key}-${vehicleType.value}`;
              initializeCollapsed(typeKey, collapse, exact, vehicleType.value);
              return (
                <Fragment key={typeKey}>
                  <SidenavCollapse
                    name={vehicleType.label}
                    icon={<Icon fontSize="small">{vehicleType.icon}</Icon>}
                    onClick={() => togglecollapsedByKey(typeKey)}
                    collapsed={collapsed[typeKey]}
                    head
                  />
                  <Collapse in={collapsed[typeKey]} timeout="auto" unmountOnExit>
                    {collapse.map((data) =>
                      renderRoute(
                        {
                          ...data,
                          replaceValue: vehicleType.value,
                        },
                        mappedTypes,
                        mappedConditions
                      )
                    )}
                  </Collapse>
                </Fragment>
              );
            })}
          </Fragment>
        );
      } else {
        initializeCollapsed(key, collapse, exact);
        returnValue = (
          <Fragment key={key}>
            <SidenavCollapse
              name={locale(name)}
              icon={icon}
              onClick={() => togglecollapsedByKey(key)}
              collapsed={collapsed[key]}
              head
            />
            <Collapse in={collapsed[key]} timeout="auto" unmountOnExit>
              {collapse.map((data) => renderRoute(data, mappedTypes, mappedConditions))}
            </Collapse>
          </Fragment>
        );
      }
    } else if (type === "collapse" && isCondition) {
      const adjustedReturns = [];
      mappedConditions.forEach((condition) => {
        const adjustedKey = `${key}-${replaceValue}-${condition.value}`;
        let adjustedRoute = route.replaceAll(`:conditionId`, condition.value);
        adjustedRoute = adjustedRoute.replaceAll(`:${replace}`, replaceValue);
        const adjustedKeyClear = adjustedKey.replaceAll("-", "");
        const active = exact
          ? collapseName === adjustedKeyClear
          : collapseName.includes(adjustedKeyClear);
        adjustedReturns.push(
          <NavLink key={adjustedKey} to={adjustedRoute}>
            <SidenavCollapse name={condition.label} icon={icon} active={active} />
          </NavLink>
        );
      });
      returnValue = adjustedReturns;
    } else if (type === "collapse") {
      let adjustedRoute = route;
      let adjustedKey = key;
      if (replace) {
        adjustedKey = `${key}-${replaceValue}`;
        adjustedRoute = route.replaceAll(`:${replace}`, replaceValue);
      }
      const adjustedKeyClear = adjustedKey.replaceAll("-", "");
      const active = exact
        ? collapseName === adjustedKeyClear
        : collapseName.includes(adjustedKeyClear);
      returnValue = href ? (
        <Link
          href={href}
          key={adjustedKey}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavCollapse
            name={locale(name)}
            icon={icon}
            active={active}
            noCollapse={noCollapse}
          />
        </Link>
      ) : (
        <NavLink key={adjustedKey} to={adjustedRoute}>
          <SidenavCollapse name={locale(name)} icon={icon} active={active} />
        </NavLink>
      );
    } else if (type === "title") {
      returnValue = (
        <MDTypography
          key={key}
          color={textColor}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </MDTypography>
      );
    } else if (type === "divider") {
      returnValue = (
        <Divider
          key={key}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    }

    return returnValue;
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((data) => {
    const updatedData = {
      ...data,
    };
    if (data.key === "dashboard-showroomBranch" && data.type === "collapse" && currentUser) {
      updatedData.replaceValue = currentUser.showroom_id;
    }
    return renderRoute(updatedData, types, conditions);
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="VehicleType" width="2rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <Locales />
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
