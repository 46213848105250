import Compressor from "compressorjs";

/**
 *  compressorjs works based on the canvas API which is async API based on the callbacks.
 *
 * this function is just a small wrapper around the callback API with promises based interface for ease use with async/await accross the application
 */
export default async function compressImage(file) {
  const compressionPromise = new Promise((resolve, reject) => {
    // eslint-disable-next-line
    new Compressor(file, {
      quality: 0.6,
      maxHeight: 1080,

      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
  return compressionPromise;
}
