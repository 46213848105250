import { authAxios } from "services";

export const fetchAll = (filters, page, searchParams = {}, sortBy = "", sortOrder = "asc") => {
  const adjustedFilters = Object.keys(filters).map((filterIndex) => [
    `filters[${filterIndex}]`,
    filters[filterIndex],
  ]);
  const urlParams = new URLSearchParams(adjustedFilters);

  urlParams.append("page", page);

  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      urlParams.append(key, searchParams[key]);
    }
  });

  if (sortBy) {
    urlParams.append("sort_by", sortBy);
    urlParams.append("sort_order", sortOrder);
  }

  return authAxios().get(`/vehicleEntries?${urlParams.toString()}`);
};

export const create = (data) => authAxios().post(`/vehicleEntries`, data);

export const fetchById = (id) => authAxios().get(`/vehicleEntries/${id}`);

export const approveById = (id) => authAxios().post(`/vehicleEntries/${id}/approve`);

export const updateById = (id, data) => authAxios().post(`/vehicleEntries/${id}?_method=PUT`, data);

export const deleteById = (id) => authAxios().delete(`/vehicleEntries/${id}`);
