import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/ServiceCenters";
import { fetchOptions as fetchBrandOptions } from "services/Brands";
import { fetchOptions as fetchServiceOptions } from "services/Services";
import { fetchOptions as fetchVehicleTypeOptions } from "services/VehicleTypes";
import { fetchOptions as fetchCityOptions } from "services/Cities";
import { fetchOptions as fetchRegionsOptions } from "services/Regions/index";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function ServiceCenterFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState();
  const [coverPreview, setCoverPreview] = useState();
  const [brands, setBrands] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [services, setServices] = useState([]);
  const [cities, setCities] = useState([]);
  const [regions, setRegions] = useState([]);
  const [, dispatch] = useMaterialUIController();
  const [cityId, setCityId] = useState(null);

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  const handleMultipleChange = (event) => {
    setFieldValue("brands", event.target.value);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.image, setImagePreview);
  }, [values.image]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.cover, setCoverPreview);
  }, [values.cover]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  useEffect(() => {
    (async () => {
      fetchBrandOptions().then(
        (res) => {
          setBrands(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
      fetchServiceOptions().then(
        (res) => {
          setServices(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
      fetchVehicleTypeOptions().then(
        (res) => {
          setVehicleTypes(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
      fetchCityOptions().then(
        (res) => {
          const citiesOptions = res.data.data.data;
          citiesOptions.sort((a, b) => a.label.localeCompare(b.label));
          setCities(citiesOptions);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
      if (id) {
        try {
          const res = await fetchById(id);
          // eslint-disable-next-line prefer-destructuring
          const data = res.data.data;
          setFieldValue("nameEn", data.name.en);
          setFieldValue("nameAr", data.name.ar);
          // if no description is available the backend returns empty array, otherwise it returns the description as object containing en and ar
          if (data.description !== null && !Array.isArray(data.description)) {
            setFieldValue("descriptionEn", data.description.en ?? "");
            setFieldValue("descriptionAr", data.description.ar ?? "");
          } else {
            setFieldValue("descriptionEn", "");
            setFieldValue("descriptionAr", "");
          }
          setFieldValue("addressEn", data.address.en);
          setFieldValue("addressAr", data.address.ar);
          setFieldValue("workingHoursEn", data.working_hours.en);
          setFieldValue("workingHoursAr", data.working_hours.ar);
          setFieldValue("daysOffEn", data.days_off.en);
          setFieldValue("daysOffAr", data.days_off.ar);
          setFieldValue("cityId", data.city_id);
          setFieldValue("regionId", data.region_id);
          setCityId(data.city_id);
          setFieldValue(
            "brands",
            data.brands.map((brand) => brand.id)
          );
          setFieldValue(
            "services",
            data.services.map((service) => service.id)
          );
          setFieldValue(
            "vehicleTypes",
            data.vehicle_types.map((vehicleType) => vehicleType.id)
          );
          setFieldValue("image", data.image);
          setFieldValue("cover", data.cover);
          setFieldValue("website", data.website ?? "");
          setFieldValue("youtube", data.youtube ?? "");
          setFieldValue("instagram", data.instagram ?? "");
          setFieldValue("facebook", data.facebook ?? "");
          setFieldValue("maps", data.maps ?? "");
          setFieldValue("phoneNumber", data.phone_number);
        } catch (err) {
          setErrorSB(dispatch, `${err.response.data.error.message}`);
        }
      }
    })();
  }, []);
  useEffect(() => {
    // Fetch regions when cityId changes
    if (cityId) {
      fetchRegionsOptions(cityId).then(
        (res) => {
          const regionsOptions = res.data.data.data;
          regionsOptions.sort((a, b) => a.label.localeCompare(b.label));
          setRegions(regionsOptions);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, [cityId]);

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setCityId(selectedCityId);
    setFieldValue("cityId", selectedCityId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameEn"
            value={values.nameEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameEn && touched.nameEn}
            helperText={errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameAr"
            value={values.nameAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameAr && touched.nameAr}
            helperText={errors.nameAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("descriptionEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="descriptionEn"
            value={values.descriptionEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.descriptionEn && touched.descriptionEn}
            helperText={errors.descriptionEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("descriptionAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="descriptionAr"
            value={values.descriptionAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.descriptionAr && touched.descriptionAr}
            helperText={errors.descriptionAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("addressEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="addressEn"
            value={values.addressEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.addressEn && touched.addressEn}
            helperText={errors.addressEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("addressAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="addressAr"
            value={values.addressAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.addressAr && touched.addressAr}
            helperText={errors.addressAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("workingHoursEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="workingHoursEn"
            value={values.workingHoursEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.workingHoursEn && touched.workingHoursEn}
            helperText={errors.workingHoursEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("workingHoursAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="workingHoursAr"
            value={values.workingHoursAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.workingHoursAr && touched.workingHoursAr}
            helperText={errors.workingHoursAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("daysOffEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="daysOffEn"
            value={values.daysOffEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.daysOffEn && touched.daysOffEn}
            helperText={errors.daysOffEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("daysOffAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="daysOffAr"
            value={values.daysOffAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.daysOffAr && touched.daysOffAr}
            helperText={errors.daysOffAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("vehicle_type")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="vehicleTypes"
              name="vehicleTypes"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vehicleTypes}
              error={errors.vehicleTypes && touched.vehicleTypes}
              multiple
            >
              {vehicleTypes.map((vehicleType) => (
                <MenuItem key={vehicleType.value} value={vehicleType.value}>
                  {vehicleType.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brandId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("brand")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="brands"
              name="brands"
              onChange={handleMultipleChange}
              onBlur={handleBlur}
              value={values.brands}
              error={errors.brands && touched.brands}
              multiple
            >
              {brands.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  {brand.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brandId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("servicecs")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="services"
              name="services"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.services}
              error={errors.services && touched.services}
              multiple
            >
              {services.map((service) => (
                <MenuItem key={service.value} value={service.value}>
                  {service.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.serviceId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("image")}
          </MDTypography>
          {imagePreview && <MDAvatar src={imagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="image"
            name="image"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "image");
            }}
            onBlur={handleBlur}
            error={errors.image && touched.image}
            helperText={errors.image}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("cover")}
          </MDTypography>
          {coverPreview && <MDAvatar src={coverPreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="cover"
            name="cover"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "cover");
            }}
            onBlur={handleBlur}
            error={errors.cover && touched.cover}
            helperText={errors.cover}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("phone")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("website")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.website && touched.website}
            helperText={errors.website}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("youtube")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="youtube"
            value={values.youtube}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.youtube && touched.youtube}
            helperText={errors.youtube}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("instagram")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="instagram"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.instagram && touched.instagram}
            helperText={errors.instagram}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("facebook")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.facebook && touched.facebook}
            helperText={errors.facebook}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("maps")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="maps"
            value={values.maps}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.maps && touched.maps}
            helperText={errors.maps}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("city")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="cityId"
              name="cityId"
              onChange={handleCityChange}
              onBlur={handleBlur}
              value={values.cityId}
              error={errors.cityId && touched.cityId}
            >
              {cities.map((city) => (
                <MenuItem key={city.value} value={city.value}>
                  {city.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.cityId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("region")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="regionId"
              name="regionId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.regionId}
              error={errors.regionId && touched.regionId}
            >
              {regions.map((region) => (
                <MenuItem key={region.value} value={region.value}>
                  {region.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.regionId}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default ServiceCenterFormik;
