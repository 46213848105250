import { authAxios, unauthAxios } from "services";

export const login = (phoneNumber, password) =>
  unauthAxios().post(`/login`, {
    phone_number: phoneNumber,
    password,
  });

export const getCurrentUser = () => authAxios().get(`/me`);

export const register = (phoneNumber, password) =>
  unauthAxios().post(`/login`, {
    phone_number: phoneNumber,
    password,
  });
