// eslint-disable-next-line
export const translation = /** @type {const} */ ({
  en: {
    attributes_table: "Attributes Table",
    vehicles: "Vehicles",
    users: "Users",
    new_cars: "New Cars",
    new_motorcycles: "New Motorcycles",
    new_buses: "New Buses",
    new_trucks: "New Trucks",
    used_cars: "Used Cars",
    used_motorcycles: "Used Motorcycles",
    used_buses: "Used Buses",
    used_trucks: "Used Trucks",
    imported_cars: "Imported Cars",
    imported_motorcycles: "Imported Motorcycles",
    imported_buses: "Imported Buses",
    imported_trucks: "Imported Trucks",
    trucks_machinery: "Trucks & Machinery",
    showrooms: "Showrooms",
    service_centers: "Service Centers",
    servicecs: "Services",
    sell_vehicle: "Sell My Vehicle",
    compare: "Compare",
    uncompare: "Uncompare",
    tyres: "Tyres",
    news: "News",
    related_news: "Related News",
    language: "Languages",
    login: "Login",
    profile: "Profile",
    edit_profile: "Edit Profile",
    wishlist: "Wishlist",
    my_vehicles: "My Vehicles",
    notifications: "Notifications",
    logout: "Logout",
    looking_for: "Looking for",
    cars: "Cars",
    car: "Car",
    motorcycles: "Motorcycles",
    motorcycle: "Motorcycle",
    buses: "Buses",
    bus: "Bus",
    trucks: "Trucks",
    truck: "Truck",
    new: "New",
    used: "Used",
    imported: "Imported",
    machinery_equipment: "Machinery & Equipment",
    new_car_offers: "New Car Offers",
    new_car_releases: "New Car Releases",
    buy_used_car: "Buy Used Car",
    buy_imported_car: "Buy Imported Car",
    model: "Model",
    year: "Year",
    starts_from: "Starts from",
    currentlyUnavailableNewVechicle: "Currently Unavailable",
    free_maintenance: "Free Maintenance",
    receive_free_maintenance: "Receive free maintenance",
    cash_discount: "Cash Discount",
    view_more: "View More",
    home: "Home",
    featured_showrooms: "Featured Showrooms",
    branches: "Branches",
    find_your_brand: "Find Your Brand",
    show_more: "Show More",
    show_less: "Show Less",
    recently_added: "Recently Added",
    new_offers: "New Offers",
    class: "Class",
    classes: "Classes",
    maintenance_table: "Maintenance Table",
    price: "Price",
    min_deposit: "Min Deposit",
    min_installment: "Min Installment",
    contact_showroom: "Contact Showroom",
    contact_seller: "Contact Seller",
    gallery: "Gallery",
    highlights: "Highlights",
    equipment: "Equipment",
    available_colors: "Available Colors",
    similar: "Similar",
    request: "Request",
    place_ad_now: "Place Your Ad Now",
    sell_my_car: "Sell My Car",
    sell_my_bus: "Sell My Bus",
    sell_my_motorcycle: "Sell My Motorcycle",
    sell_my_truck: "Sell My Truck",
    class_differences: "Class Difference",
    find_a: "Find a",
    brand: "Brand",
    city: "City",
    region: "Region",
    color: "Color",
    mileage: "Mileage",
    price_range: "Price Range",
    call: "Call",
    request_call_back: "Request Call Back",
    submit: "Submit",
    reset: "Reset",
    send: "Send",
    full_name: "Full Name",
    mobile_number: "Mobile Number",
    whatsapp_note: "NOTE: If not in Egypt please provide a WhatsApp number",
    add_comment: "Add Comment",
    report_this: "Report this",
    follow_us: "Follow Us",
    about_us: "About Us",
    call_showroom: "Call Showroom",
    call_center: "Call Service Center",
    remove_wishlist: "Remove from wishlist",
    car_sure_to_remove_wishlist: "Are you sure you want to remove this car from wishlist?",
    bus_sure_to_remove_wishlist: "Are you sure you want to remove this bus from wishlist?",
    truck_sure_to_remove_wishlist: "Are you sure you want to remove this truck from wishlist?",
    motorcycle_sure_to_remove_wishlist:
      "Are you sure you want to remove this motorcycle from wishlist?",
    car_sure_to_remove_compare: "Are you sure you want to remove this car from compare?",
    bus_sure_to_remove_compare: "Are you sure you want to remove this car from compare?",
    truck_sure_to_remove_compare: "Are you sure you want to remove this car from compare?",
    motorcycle_sure_to_remove_compare: "Are you sure you want to remove this car from compare?",
    remove: "Remove",
    cancel: "Cancel",
    compare_list_full: "Compare list is full for this category. Remove from the list one",
    add_to_compare: "Add to Compare",
    add_another: "Add Another",
    signin: "Sign in",
    signup: "Sign up",
    remember_me: "Remember me",
    forgot_password: "Forgot password",
    remember_password: "Remember password",
    signin_with: "Sign in with",
    facebook: "Facebook",
    google: "Google",
    whatsapp: "WhatsApp",
    phone: "Phone Number",
    email: "Email",
    password: "Password",
    current_password: "Current Password",
    new_password: "New Password",
    change_password: "Change Password",
    change_password_successfuly: "Password has changed successfuly! Login now.",
    name: "Name",
    confirm_password: "Confirm Password",
    enter_phone_email_account: "Enter phone number or email associated with your account",
    verification: "Verification",
    we_sent_code: "We have sent OTP to your phone.",
    didnt_receive_code: "Didn't receive SMS?",
    resend_code: "Resend Code",
    verify: "Verify",
    passwords_dont_match: "Passwords don't match",
    about_your_vehicle: "About Your Vehicle",
    personal_info: "Personal Information",
    vehicle_type: "Vehicle Type",
    add_images: "Add Images",
    add_images_note:
      "Recommended to photograph: Front Side - Back Side - Right Side - Left Side - Interior - Dashboard",
    next: "Next",
    previous: "Previous",
    preview: "Preview",
    view: "View",
    national_id: "National ID",
    license_id: "License ID",
    truck_classification: "Classification",
    truck_type: "Type",
    truck_category: "Category",
    change_profile_pic: "Change profile picture",
    save_changes: "Save Changes",
    find: "Find",
    error_occured: "Error occurred!",
    confirm: "Confirm",
    confirm_request: "Confirm this request?",
    fleet: "Company",
    fleet_name: "Company Name",
    position: "Position",
    cash: "Cash",
    number_of: "Number of ",
    comment: "Comment",
    requested_successfuly: "Requested Successfuly",
    reported_successfuly: "Reported Successfuly",
    your_ad_ready: "Your ad is ready.",
    publish: "Publish",
    description: "Description",
    in_en: "in English",
    in_ar: "in Arabic",
    ad_placed_successfuly: "Ad is placed successfuly",
    removed_listing_successfuly: "Listing Has Been Removed",
    view_my_vehicle: "View to edit",
    edit_my_vehicle: "Edit vehicle",
    edit: "Edit",
    registered_successfuly: "Registered successfuly. You can login now.",
    privacy_policy: "Privacy Policy",
    terms_conditions: "Terms & Conditions",
    quick_links: "Quick Links",
    connect_w_us: "Connect With Us",
    tags: "Tags",
    working_hours: "Working Hours",
    days_off: "Days Off",
    sort_by: "Sort By",
    most_recent: "Most Recent",
    acknowledgement: "I acknowledge that all the previous data is at my own resposibility",
    min_year: "Min. Year",
    max_year: "Max. Year",
    min_price: "Min. Price",
    max_price: "Max. Price",
    copy_link: "Copy Link",
    required_field: "This field is required",
    min_amount: "Minimum amount",
    max_amount: "Maximum amount",
    min_mileage: "Min. mileage",
    max_mileage: "Max. mileage",
    show: "Show",
    hide: "Hide",
    no_sold: "It seems you don't have sold %vehicles%",
    no_wishlist: "It seems you don't have %vehicles% in your wishlist",
    no_compare: "It seems you don't have %vehicles% in your compare list",
    sure_to_delete: "Are you sure you want to delete this?",
    edit_phone: "Edit phone number",
    updated_successfuly: "Updated successfuly",
    update: "Update",
    companies: "Companies",
    our_team: "Our team",
    addedToCompare: "Added to compare list",
    rentable: "Rentable",
    rent: "Rent",
    month: "Month",
    max_chars: "Maximum number is %NUM% characters",
    no_attributes: "No features for the selected brand or category found",
    car_transmissiontype_dsg: "No features for the selected brand or category found",
    visitUsHere: "Visit Us Here",
    workHours: "Working Hours",
    location: "Location",
    daysOff: "Days Off",
    hours: "Hours",
    addedToWishlistSuccess: "Added to your Wishlist",
    removedFromWishlistSuccess: "Removed from your Wishlist",
    titleEn: "Title En",
    titleAr: "Title Ar",
    unitEn: "Unit En",
    unitAr: "Unit Ar",
    code: "Code",
    condititions: "Conditions",
    icon: "Icon",
    inputType: "Input Type",
    optionCode: "Option Code",
    optionEn: "Option En",
    optionAr: "Option Ar",
    addOption: "Add option",
    isRequired: "Is Required",
    usedInFilter: "Used in filtering",
    usedInSort: "Used in sorting",
    addNew: "Add New",
    search: "Search",
    entriesPerPage: "entries per page",
    showing: "Showing",
    entries: "entries",
    modelsTable: "Models Table",
    id: "Id",
    title: "Title",
    mainImage: "Main Image",
    createdAt: "Created At",
    updatedAt: "Updated At",
    modelsForm: "Vehicle Models Form",
    variantsTable: "Variants Table",
    variantsForm: "Vehicle Variants Form",
    showAsNew: "Show as new",
    classesTable: "Classes Table",
    variant: "Variant",
    priceReleaseDay: "Price Release Date",
    customAttributes: "Custom Attributes",
    nameEn: "Name En",
    nameAr: "Name Ar",
    subtitleEn: "Subtitle En",
    subtitleAr: "Subtitle Ar",
    image: "Image",
    link: "Link",
    place: "Place",
    showroom: "Showroom",
    serviceCenter: "Service Center",
    bannersForm: "Banners Form",
    actions: "Actions",
    brandsForm: "Brands Form",
    brandsTable: "Brands Table",
    regionsForm: "Regions Form",
    regionsTable: "Regions Table",
    descriptionEn: "Description En",
    descriptionAr: "Description Ar",
    addressEn: "address En",
    addressAr: "address Ar",
    workingHoursEn: "Working hours En",
    workingHoursAr: "Working hours Ar",
    daysOffEn: "Days off En",
    daysOffAr: "Days off Ar",
    cover: "Cover",
    website: "Website",
    youtube: "Youtube",
    instagram: "Instagram",
    maps: "Maps",
    serviceCenterForm: "Service Centers Form",
    serviceCenterTable: "Service Centers Table",
    mapLink: "Map Link",
    latitude: "Latitude",
    longitude: "Longitude",
    showroomBranchesForm: "Showroom Branches Form",
    showroomBranchesTable: "Showroom Branches Table",
    role: "Role",
    admin: "Admin",
    showroomAdmin: "Showroom Admin",
    user: "User",
    usersForm: "Users Form",
    usersTable: "Users Table",
    changePasswordForm: "Change password form",
    parentCategory: "Parent Category",
    vehicleCategoriesForm: "Vehicle Categories Form",
    vehicleCategoriesTable: "Vehicle Categories Table",
    vehicleClassesForm: "Vehicle Classes Form",
    vehicleConditionsForm: "Vehicle Conditions Form",
    vehicleConditionsTable: "Vehicle Conditions Table",
    dashboardIcon: "Dashboard Icon",
    vehicletypesForm: "vehicle types form",
    vehicletypesTable: "vehicle types table",
    vehicleEntriesForm: "Vehicle Entries Form",
    vehicleEntriesTable: "Vehicle Entries Table",
    approved: "Approved",
    entriesForm: "Entries Form",
    entriesTable: "Entries Table",
    branch: "Branch",
    showroomsForm: "Showrooms Form",
    showroomsTable: "Showrooms Table",
    topicEn: "Topic En",
    topicAr: "Topic Ar",
    articlesForm: "Articles Form",
    articlesTable: "Articles Table",
    citiesForm: "Cities Form",
    citiesTable: "Cities Table",
    servicesForm: "Services Form",
    servicesTable: "Services Table",
    value: "Value",
    settingsForm: "Settings Form",
    settingsTable: "Settings Table",
    dashboard: "Dashboard",
    generalConfiguration: "General Configuration",
    contentManagment: "Content Management",
    vehicleConfiguration: "Vehicle Configuration",
    entitiesManagement: "Entities Management",
    attributes: "Attributes",
    models: "Models",
    variants: "Variants",
    userChangePassword: "User Change Password",
    showroomBranches: "Showroom Branches",
    serviceCenters: "Service Centers",
    brands: "Brands",
    types: "Types",
    conditions: "Conditions",
    categories: "Categories",
    articles: "Articles",
    banners: "Banners",
    regions: "Regions",
    cities: "Cities",
    services: "Services",
    settings: "Settings",
    brandName: "Romotors Dashboard",
    chooseVehicleCategory: "Choose the vehicle category you are selling",
  },
  ar: {
    chooseVehicleCategory: "اختر نوع الشاحنة أو المعدة التي تبيعها",
    brandName: "لوحة تحكم روموتورز",
    settings: "الإعدادات",
    services: "الصيانات",
    cities: "المدن",
    regions: "المناطق",
    banners: "اللافتات",
    articles: "المقالات",
    categories: "الأنواع",
    types: "التصنيف",
    brands: "الماركات",
    serviceCenters: "مراكز الخدمة",
    showroomBranches: "فروع المعرض",
    userChangePassword: "تغيير كلمة مرور المستخدم",
    variants: "النماذج",
    models: "الموديلات",
    attributes: "الكماليات",
    dashboard: "لوحة التحكم",
    entitiesManagement: "إدارة المستخدمين",
    vehicleConfiguration: "إعدادت المركبات",
    contentManagment: "إدارة المحتوى",
    generalConfiguration: "الإعدادات العامة",
    settingsForm: "نموذج الإعدادات",
    settingsTable: "جدول الإعدادات",
    value: "القيمة",
    servicesForm: "نموذج الخدمات",
    servicesTable: "جدول الخدمات",
    citiesForm: "نموذج المدن",
    citiesTable: "جدول المدن",
    articlesForm: "نموذج المقالات",
    articlesTable: "جدول المقالات",
    topicEn: "الموضوع بالإنجليزية",
    topicAr: "الموضوع بالعربية",
    showroomsForm: "نموذج صالات العرض",
    showroomsTable: "جدول صالات العرض",
    branch: "الفرع",
    entriesForm: "نموذج الإدخالات",
    entriesTable: "جدول الإدخالات",
    approved: "معتمد",
    vehicleEntriesForm: "نموذج إدخالات المركبة",
    vehicleEntriesTable: "جدول إدخالات المركبة",
    vehicletypesForm: "نموذج أنواع المركبات",
    vehicletypesTable: "جدول أنواع المركبات",
    dashboardIcon: "أيقونة اللوحة",
    vehicleConditionsForm: "نموذج حالات المركبات",
    vehicleConditionsTable: "جدول حالات المركبات",
    vehicleClassesForm: "نموذج فئات المركبات",
    vehicleCategoriesTable: "جدول فئات المركبات",
    vehicleCategoriesForm: "نموذج فئات المركبات",
    parentCategory: "الفئة الأم",
    changePasswordForm: "نموذج تغيير كلمة المرور",
    usersTable: "جدول المستخدمين",
    usersForm: "نموذج المستخدمين",
    user: "المستخدم",
    showroomAdmin: "مسؤول المعرض",
    admin: "المسؤول",
    role: "الدور",
    showroomBranchesTable: "جدول فروع المعرض",
    showroomBranchesForm: "نموذج فروع المعرض",
    longitude: "خط الطول",
    latitude: "خط العرض",
    mapLink: "رابط الخريطة",
    serviceCenterTable: "جدول مراكز الخدمة",
    serviceCenterForm: "نموذج مراكز الخدمة",
    maps: "الخرائط",
    instagram: "إنستغرام",
    youtube: "يوتيوب",
    website: "الموقع الإلكتروني",
    cover: "الغلاف",
    daysOffEn: "أيام الإجازة بالإنجليزية",
    daysOffAr: "أيام الإجازة بالعربية",
    descriptionEn: "الوصف بالإنجليزية",
    descriptionAr: "الوصف بالعربية",
    addressEn: "العنوان بالإنجليزية",
    addressAr: "العنوان بالعربية",
    workingHoursEn: "ساعات العمل بالإنجليزية",
    workingHoursAr: "ساعات العمل بالعربية",
    regionsTable: "جدول المناطق",
    regionsForm: "نموذج المناطق",
    brandsTable: "جدول الماركات",
    brandsForm: "نموذج الماركات",
    actions: "الإجراءات",
    bannersForm: "نموذج اللافتات",
    serviceCenter: "مركز الخدمة",
    showroom: "صالة العرض",
    place: "المكان",
    link: "الرابط",
    image: "الصورة",
    subtitleEn: "العنوان الفرعي بالإنجليزية",
    subtitleAr: "العنوان الفرعي بالعربية",
    nameEn: "الاسم بالإنجليزية",
    nameAr: "الاسم بالعربية",
    condititions: "الحالات",
    customAttributes: "السمات المخصصة",
    variant: "النموذج",
    classesTable: "جدول الفئات",
    variantsForm: "نموذج نماذج المركبات",
    showAsNew: "عرض كجديد",
    variantsTable: "جدول النماذج",
    modelsForm: "نموذج طرازات المركبات",
    createdAt: "تاريخ الإنشاء",
    updatedAt: "تاريخ التحديث",
    mainImage: "الصورة الرئيسية",
    title: "العنوان",
    id: "المعرف",
    modelsTable: "جدول الطرازات",

    entries: "إدخالات",
    showing: "عرض",
    entriesPerPage: "إدخالات لكل صفحة",
    search: "بحث",
    addNew: "إضافة جديد",
    usedInSort: "يستخدم في الفرز",
    usedInFilter: "يستخدم في التصفية",
    isRequired: "مطلوب",
    addOption: "إضافة خيار",
    optionEn: "الخيار بالإنجليزية",
    optionAr: "الخيار بالعربية",
    optionCode: "كود الخيار",
    inputType: "نوع الإدخال",
    icon: "أيقونة",
    conditions: "الشروط",
    unitEn: "الوحدة بالإنجليزية",
    unitAr: "الوحدة بالعربية",
    code: "رمز",
    titleEn: "الاسم انجليزي",
    titleAr: "الاسم عربي",
    attributes_table: "جدول الكماليات",
    vehicles: "المركبات",
    users: "المستخدمين",
    new_cars: "سيارات جديدة",
    new_motorcycles: "دراجات جديدة",
    new_buses: "حافلات جديدة",
    new_trucks: "شاحنات جديدة",
    used_cars: "سيارات مستعملة",
    used_motorcycles: "دراجات مستعملة",
    used_buses: "حافلات مستعملة",
    used_trucks: "شاحنات مستعملة",
    imported_cars: "سيارات مستوردة",
    imported_motorcycles: "دراجات مستوردة",
    imported_buses: "حافلات مستوردة",
    imported_trucks: "شاحنات مستوردة",
    trucks_machinery: "شاحنات",
    showrooms: "معارض",
    service_centers: "مراكز الصيانة",
    servicecs: "الخدمات",
    sell_vehicle: "بيع سيارتى",
    compare: "مقارنة",
    uncompare: "حذف من المقارنة",
    tyres: "اطارات",
    news: "أخبار",
    related_news: "أخبار متعلقة",
    language: "اللغة",
    login: "تسجيل الدخول",
    profile: "الصفحة الشخصية",
    edit_profile: "تعديل الصفحة الشخصية",
    wishlist: "مفضلات",
    my_vehicles: "مركباتى",
    notifications: "اشعارات",
    logout: "تسجيل الخروج",
    looking_for: "تبحث عن",
    cars: "سيارات",
    car: "سيارة",
    motorcycles: "دراجات نارية",
    motorcycle: "دراجة",
    buses: "حافلات",
    bus: "حافلة",
    trucks: "شاحنات",
    truck: "شاحنة",
    new: "جديد",
    used: "مستعمل",
    imported: "مستورد",
    machinery_equipment: "آلات و معدات",
    new_car_offers: "عروض السيارات جديدة",
    new_car_releases: "أحدث السيارات الجديدة",
    buy_used_car: "اشترى سيارة مستعملة",
    buy_imported_car: "اشترى سيارة مستوردة",
    model: "موديل",
    year: "سنة",
    starts_from: "تبدأ من",
    currentlyUnavailableNewVechicle: "غير متوفر حاليا",
    free_maintenance: "صيانة مجانية",
    receive_free_maintenance: "احصل على صيانة مجانية",
    cash_discount: "خصم نقدى",
    view_more: "عرض المزيد",
    home: "الرئيسية",
    featured_showrooms: "المعارض المميزة",
    branches: "الفروع",
    find_your_brand: "ابحث عن ماركتك",
    show_more: "عرض المزيد",
    show_less: "عرض أقل",
    recently_added: "أحدث الاضافات",
    new_offers: "عروض جديدة",
    class: "الفئة",
    classes: "الفئات",
    maintenance_table: "جدول الصيانة",
    price: "السعر",
    min_deposit: "أقل مقدم",
    min_installment: "أقل قسط",
    contact_showroom: "تواصل مع المعرض",
    contact_seller: "تواصل مع البائع",
    gallery: "الصور",
    highlights: "المواصفات الرئيسية",
    equipment: "التجهيزات",
    available_colors: "الألوان المتاحة",
    similar: "مشابهة",
    request: "قدم طلب",
    place_ad_now: "ضع اعلانك الآن",
    sell_my_car: "عرض سيارتى للبيع",
    sell_my_bus: "عرض حافلتى للبيع",
    sell_my_motorcycle: "عرض دراجتى للبيع",
    sell_my_truck: "عرض شاحنتى للبيع",
    class_differences: "فرق الصنف السابق",
    find_a: "البحث عن",
    brand: "الماركة",
    city: "المدينة",
    region: "المنطقة",
    color: "اللون",
    mileage: "العداد",
    price_range: "نطاق السعر",
    call: "الاتصال",
    request_call_back: "طلب الاتصال بك",
    submit: "عرض",
    reset: "تفريغ",
    send: "ارسال",
    full_name: "الاسم بالكامل",
    mobile_number: "رقم الموبايل",
    whatsapp_note: "تنويه: فى حالة رقم موبايل دولى برجاء ادخال رقم موبايل للواتساب",
    add_comment: "اضف تعليق",
    report_this: "الابلاغ عن",
    follow_us: "تابعنا",
    about_us: "تعرف علينا",
    call_showroom: "تواصل مع المعرض",
    call_center: "تواصل مع المركز",
    remove_wishlist: "حذف من المفضلات",
    car_sure_to_remove_wishlist: "هل أنت متأكد من حذف السيارة من المفضلات؟",
    bus_sure_to_remove_wishlist: "هل أنت متأكد من حذف الحافلة من المفضلات؟",
    truck_sure_to_remove_wishlist: "هل أنت متأكد من حذف الشاخنة من المفضلات؟",
    motorcycle_sure_to_remove_wishlist: "هل أنت متأكد من حذف الدراجة من المفضلات؟",
    car_sure_to_remove_compare: "هل أنت متأكد من حذف السيارة من المقارنات؟",
    bus_sure_to_remove_compare: "هل أنت متأكد من حذف السيارة من المقارنات؟",
    truck_sure_to_remove_compare: "هل أنت متأكد من حذف السيارة من المقارنات؟",
    motorcycle_sure_to_remove_compare: "هل أنت متأكد من حذف السيارة من المقارنات؟",
    remove: "حذف",
    cancel: "اغلاق",
    compare_list_full: "قائمة المقارنات ممتلئة لهذه المجموعة. برجاء حذف من القائمة",
    add_to_compare: "اضف لقائمة المقارنة",
    add_another: "اضف",
    signin: "تسجيل الدخول",
    signup: "تسجيل",
    remember_me: "تذكرنى",
    forgot_password: "نسيت كلمة المرور",
    remember_password: "تذكرت كلمة المرور",
    signin_with: "تسجيل الدخول عبر",
    facebook: "فيسبوك",
    google: "جوجل",
    whatsapp: "واتساب",
    phone: "رقم الهاتف",
    email: "البريد الالكترونى",
    password: "كلمة المرور",
    current_password: "كلمة المرور الحالية",
    new_password: "كلمة المرور الجديدة",
    change_password: "تغيير كلمة المرور",
    change_password_successfuly: "تم تغيير كلمة المرور بنجاح! سجل الدخول الآن.",
    name: "الاسم",
    confirm_password: "تأكيد كلمة المرور",
    enter_phone_email_account: "أدخل رقم الهاتف أو البريد الاكترونى الخاص بحسابك",
    verification: "تأكيد الهوية",
    we_sent_code: "لقد أرسلنا الكود إلى هاتفك.",
    didnt_receive_code: "لم تستقبل الكود؟",
    resend_code: "ارسال كود آخر",
    verify: "تأكيد",
    passwords_dont_match: "كلمات السر غير متوافقة",
    about_your_vehicle: "تفاصيل المركبة",
    personal_info: "تفاصيل شخصية",
    vehicle_type: "نوع المركبة",
    add_images: "اضف صور",
    add_images_note:
      "المرشح للتصوير: الجانب الامامى - الجانب الخلفى - الجانب الايمن - الجانب الايسر - من الداخل - قمرة القيادة",
    next: "التالى",
    previous: "السابق",
    preview: "معاينة",
    view: "عرض",
    national_id: "الرقم القومى",
    license_id: "رقم الرخصة",
    truck_classification: "الفئة الرئيسية",
    truck_type: "الهيكل",
    truck_category: "التصنيف",
    change_profile_pic: "تغيير الصورة الشخصية",
    save_changes: "حفظ التغييرات",
    find: "بحث",
    error_occured: "حذث خطأ!",
    confirm: "تأكيد",
    confirm_request: "تأكيد الطلب؟",
    fleet: "شركة",
    fleet_name: "اسم الشركة",
    position: "المركز",
    cash: "نقدى",
    number_of: "عدد ال",
    comment: "تعليق",
    requested_successfuly: "تم تقديم الطلب بنجاح",
    reported_successfuly: "تم الابلاغ بنجاح",
    your_ad_ready: "اعلانك جاهز.",
    publish: "اعلن الآن",
    in_en: "بالإنجليزية",
    description: "الوصف",
    in_ar: "بالعربية",
    ad_placed_successfuly: "تم نشر الاعلان بنجاح",
    view_my_vehicle: "عرض للتعديل",
    edit_my_vehicle: "تعديل المركبة",
    edit: "تعديل",
    registered_successfuly: "تم التسجيل بنجاح. يمكنك تسجيل الدخول الآن",
    privacy_policy: "سياسة الخصوصية",
    terms_conditions: "الشروط و الأحكام",
    quick_links: "روابط سريعة",
    connect_w_us: "تواصل معنا",
    tags: "الروابط",
    working_hours: "مواعيد العمل",
    days_off: "أيام الاجازات",
    sort_by: "ترتيب حسب",
    most_recent: "المضاف مؤخرا",
    acknowledgement: "أقر بأن كل البيانات السابقة على مسئوليتى الشخصية",
    min_year: "أقل سنة",
    max_year: "أكبر سنة",
    min_price: "أقل سعر",
    max_price: "أكبر سعر",
    copy_link: "نسخ الرابط",
    required_field: "هذا الحقل مطلوب",
    min_amount: "الحد الأدنى",
    max_amount: "الحد الاقصى",
    min_mileage: "أقل عداد",
    max_mileage: "أكبر عداد",
    show: "عرض",
    hide: "اخفاء",
    no_sold: "لا يوجد %vehicles% لديك معروضة للبيع",
    no_wishlist: "لا يوجد %vehicles% لديك فى المفضلات",
    no_compare: "لا يوجد %vehicles% لديك فى المقارنات",
    sure_to_delete: "هل أنت متأكد من الحذف؟",
    edit_phone: "تعديل رقم الهاتف",
    updated_successfuly: "تم التعديل بنجاح",
    update: "تعديل",
    companies: "الشركة",
    our_team: "فريقنا",
    addedToCompare: "تم الاضافة لقائمة المقارنات",
    rentable: "قابل للإيجار",
    rent: "سعر الإيجار",
    month: "شهر",
    max_chars: "الحد الأقصى للحروف %NUM% حرف",
    no_attributes: "لا يوجد كماليات للاختيار",
    visitUsHere: "موقعنا هنا",
    workHours: "مواعيد العمل",
    location: "العنوان",
    daysOff: "ايام الاجازات",
    hours: "الساعات",
    removed_listing_successfuly: "تم مسح الاعلان",
    addedToWishlistSuccess: "تمت الإضافة إلى قائمة الرغبات الخاصة بك",
    removedFromWishlistSuccess: "تمت الإزالة من قائمة الرغبات الخاصة بك",
  },
});
