import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/VehicleConditions";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function VehicleConditionFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [preview, setPreview] = useState();
  const [, dispatch] = useMaterialUIController();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!values.image) {
      setPreview(undefined);
      return;
    }

    if (values.image instanceof File) {
      const objectUrl = URL.createObjectURL(values.image);
      setPreview(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setPreview(`${process.env.REACT_APP_BACKEND_URL}/storage/${values.image}`);
  }, [values.image]);

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue("image", undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue("image", e.target.files[0]);
  };

  if (id) {
    useEffect(() => {
      fetchById(id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("code", res.data.data.code);
          setFieldValue("image", res.data.data.image);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }, []);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("code")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.code && touched.code}
            helperText={errors.code}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("image")}
          </MDTypography>
          {preview && <MDAvatar src={preview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="image"
            name="image"
            type="file"
            onChange={onSelectFile}
            onBlur={handleBlur}
            error={errors.image && touched.image}
            helperText={errors.image}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default VehicleConditionFormik;
