import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Attributes";
import { fetchOptions as fetchConditionOptions } from "services/VehicleConditions";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import useLocale from "context/useLocale";

function AttributeFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id, typeId } = useParams();
  const [, dispatch] = useMaterialUIController();
  const [iconPreview, setIconPreview] = useState();
  const attributeTypes = {
    text: "Text",
    checkbox: "Checkbox",
    select: "Select",
    multiselect: "Multiselect",
    file: "File",
  };
  const [conditions, setConditions] = useState([]);

  const addOption = () => {
    const option = { ar: "", en: "", code: "" };
    setFieldValue("options", [...values.options, option]);
  };

  const updateOption = (index, field, value) => {
    const updatedOptions = [...values.options];
    updatedOptions[index][field] = value;
    setFieldValue("options", updatedOptions);
  };

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.icon, setIconPreview);
  }, [values.icon]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  useEffect(async () => {
    fetchConditionOptions().then(
      (res) => {
        setConditions(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(typeId, id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          if (res.data.data.unit) {
            setFieldValue("unitEn", res.data.data.unit.en);
            setFieldValue("unitAr", res.data.data.unit.ar);
          }
          setFieldValue("icon", res.data.data.icon);
          setFieldValue("code", res.data.data.code);
          setFieldValue("inputType", res.data.data.input_type);
          setFieldValue("required", !!res.data.data.required);
          setFieldValue("isFilterable", !!res.data.data.is_filterable);
          setFieldValue("isSortable", !!res.data.data.is_sortable);
          const attributeConditions = res.data.data.vehicle_conditions.map(
            (condition) => condition.id
          );
          setFieldValue("conditions", attributeConditions);
          const attributeOptions = res.data.data.options.map((option) => ({
            code: option.value,
            ar: option.label.ar,
            en: option.label.en,
          }));
          setFieldValue("options", attributeOptions);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("unitEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="unitEn"
            value={values.unitEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.unitEn && touched.unitEn}
            helperText={errors.unitEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("unitAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="unitAr"
            value={values.unitAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.unitAr && touched.unitAr}
            helperText={errors.unitAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("code")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="code"
            value={values.code}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.code && touched.code}
            helperText={errors.code}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("condititions")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="conditions"
              name="conditions"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.conditions}
              error={errors.conditions && touched.conditions}
              multiple
            >
              {conditions.map((condition) => (
                <MenuItem key={condition.value} value={condition.value}>
                  {condition.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brandId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("icon")}
          </MDTypography>
          {iconPreview && <MDAvatar src={iconPreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="icon"
            name="icon"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "icon");
            }}
            onBlur={handleBlur}
            error={errors.icon && touched.icon}
            helperText={errors.icon}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("inputType")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="inputType"
              name="inputType"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.inputType}
              error={errors.inputType && touched.inputType}
            >
              {Object.keys(attributeTypes).map((value) => (
                <MenuItem key={value} value={value}>
                  {attributeTypes[value]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.inputType}</FormHelperText>
          </FormControl>
        </Grid>
        {(values.inputType === "select" || values.inputType === "multiselect") && (
          <>
            {values.options.map((option, index) => (
              <>
                <Grid item xs={4}>
                  <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                    {locale("optionCode")}
                  </MDTypography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="optionCode"
                    value={option.code}
                    onChange={(e) => updateOption(index, "code", e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                    {locale("optionEn")}
                  </MDTypography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="optionEn"
                    value={option.en}
                    onChange={(e) => updateOption(index, "en", e.target.value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                    {locale("optionAr")}
                  </MDTypography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="optionAr"
                    value={option.ar}
                    onChange={(e) => updateOption(index, "ar", e.target.value)}
                  />
                </Grid>
              </>
            ))}
            <Grid item xs={12}>
              <MDButton
                type="button"
                variant="contained"
                color="info"
                fullWidth
                onClick={addOption}
              >
                {locale("addOption")}
              </MDButton>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="required"
                  checked={values.required}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.required && touched.required}
                />
              }
              label={locale("isRequired")}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="isFilterable"
                  checked={values.isFilterable}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.isFilterable && touched.isFilterable}
                />
              }
              label={locale("usedInFilter")}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="isSortable"
                  checked={values.isSortable}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.isSortable && touched.isSortable}
                />
              }
              label={locale("usedInSort")}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default AttributeFormik;
