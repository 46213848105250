import { Navigate } from "react-router-dom";

import PropTypes from "prop-types";
import { getCurrentUser } from "services/User";
import { useMaterialUIController, setCurrentUser } from "context";
import { useState } from "react";

function ProtectedRoute({ meta, roles, children }) {
  const [authorized, setAuthorized] = useState(true);
  const [loading, setLoading] = useState(true);
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser } = controller;
  const userToken = localStorage.getItem("token");
  const isLoggedIn = userToken && userToken !== "undefined";
  if (meta.auth && !isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  if (!meta.auth && isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }
  if (!meta.auth && !isLoggedIn) {
    return children;
  }
  if (!currentUser) {
    getCurrentUser().then(
      (res) => {
        if (
          roles &&
          roles.length > 0 &&
          !roles.some((item) => res.data.data.roles.includes(item))
        ) {
          setAuthorized(false);
        } else {
          setCurrentUser(dispatch, res.data.data);
          setLoading(false);
        }
      },
      () => {
        setLoading(false);
        setAuthorized(false);
      }
    );
  }
  if (loading) {
    return null;
  }
  if (!authorized) {
    localStorage.removeItem("token");
    return <Navigate to="/login" replace />;
  }
  return children;
}

ProtectedRoute.defaultProps = {
  meta: {},
  children: null,
};

ProtectedRoute.propTypes = {
  meta: PropTypes.shape({
    auth: PropTypes.bool,
  }),
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
};

export default ProtectedRoute;
