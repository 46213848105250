import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/VehicleModels";
import { fetchOptions as fetchBrandOptions } from "services/Brands";
import { fetchOptions as fetchCategoryOptions } from "services/VehicleCategories";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function VehicleModelFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id, typeId } = useParams();
  const [mainImagePreview, setMainImagePreview] = useState();
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { direction } = controller;

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.mainImage, setMainImagePreview);
  }, [values.mainImage]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  useEffect(async () => {
    fetchBrandOptions({
      type_id: typeId,
    }).then(
      (res) => {
        setBrands(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    fetchCategoryOptions({
      vehicle_type_id: typeId,
    }).then(
      (res) => {
        setCategories(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("mainImage", res.data.data.main_image);
          setFieldValue(
            "categoryId",
            res.data.data.vehicle_category ? res.data.data.vehicle_category.id : ""
          );
          setFieldValue("brandId", res.data.data.brand.id);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("unitEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("unitAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        {!!categories.length && (
          <Grid item xs={12}>
            <MDTypography component="p" variant="button" color="text" fontWeight="medium">
              {locale("truck_category")}
            </MDTypography>
            <FormControl fullWidth size="large">
              <Select
                id="categoryId"
                name="categoryId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.categoryId}
                error={errors.categoryId && touched.categoryId}
              >
                {categories.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.categoryId}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            Brand
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="brandId"
              name="brandId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.brandId}
              error={errors.brandId && touched.brandId}
            >
              {brands.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  {brand.label[direction === "ltr" ? "en" : "ar"]}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brandId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("mainImage")}
          </MDTypography>
          {mainImagePreview && <MDAvatar src={mainImagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="mainImage"
            name="mainImage"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "mainImage");
            }}
            onBlur={handleBlur}
            error={errors.mainImage && touched.mainImage}
            helperText={errors.mainImage}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default VehicleModelFormik;
