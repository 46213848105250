import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/showrooms?page=${page}`);

export const fetchOptions = () => authAxios().get(`/showrooms/options`);

export const create = (data) => authAxios().post(`/showrooms`, data);

export const fetchById = (id) => authAxios().get(`/showrooms/${id}`);

export const deleteById = (id) => authAxios().delete(`/showrooms/${id}`);

export const updateById = (id, data) => authAxios().post(`/showrooms/${id}?_method=PUT`, data);
