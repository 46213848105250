import InputAdornment from "@mui/material/InputAdornment";

import { useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Image from "material-ui-image";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/BasicLayout";

// Images
import bgImage from "assets/images/bg-login.jpg";
import logo from "assets/images/logo-en.png";

// APIs
import { login } from "services/User";
import Egypt from "components/Icons/Egypt";
import useLocale from "context/useLocale";
import { rawPhoneNumber } from "util/general";

function Basic() {
  const locale = useLocale();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const logoStyle = {
    display: "block",
    position: "relative",
    width: "150px",
    height: "auto",
    margin: "40px auto",
  };

  const submit = () => {
    setError("");
    login(`+20${rawPhoneNumber(phoneNumber)}`, password).then(
      (res) => {
        localStorage.setItem("token", res.data.data.token);
        return navigate("/dashboard/");
      },
      (err) => setError(err.response.data.message ? err.response.data.message : "Failed to login")
    );
  };

  return (
    <BasicLayout image={bgImage}>
      <img src={logo} alt="Logo" style={logoStyle} />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" my={1}>
            {locale("signin")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="text"
                label={locale("phone")}
                name="username"
                fullWidth
                onChange={(e) => setPhoneNumber(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MDBox display="flex" alignItems="center">
                        <Egypt width={15} height={15} />
                        <MDBox flexGrow={1} ml={1}>
                          <MDTypography variant="p">+20</MDTypography>
                        </MDBox>
                      </MDBox>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label={locale("password")}
                name="password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={submit}>
                {locale("login")}
              </MDButton>
            </MDBox>
            <MDTypography variant="h6" fontWeight="regular" color="error" my={1}>
              {error}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
