import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Regions";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import { fetchOptions as fetchCityOptions } from "services/Cities";
import useLocale from "context/useLocale";

function RegionFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const [cities, setCities] = useState([]);

  useEffect(async () => {
    fetchCityOptions().then(
      (res) => {
        setCities(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("nameEn", res.data.data.name.en);
          setFieldValue("nameAr", res.data.data.name.ar);
          setFieldValue("cityId", res.data.data.city.id);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("city")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="cityId"
              name="cityId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.cityId}
              error={errors.cityId && touched.cityId}
            >
              {cities.map((city) => (
                <MenuItem key={city.value} value={city.value}>
                  {city.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.cityId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameEn"
            value={values.nameEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameEn && touched.nameEn}
            helperText={errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameAr"
            value={values.nameAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameAr && touched.nameAr}
            helperText={errors.nameAr}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default RegionFormik;
