import { authAxios } from "services";

export const fetchAll = (typeId, page, searchParams = {}, sortBy = "", sortOrder = "asc") => {
  const urlParams = new URLSearchParams();
  urlParams.append("page", page);

  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      urlParams.append(key, searchParams[key]);
    }
  });

  if (sortBy) {
    urlParams.append("sort_by", sortBy);
    urlParams.append("sort_order", sortOrder);
  }

  return authAxios().get(`/vehicleTypes/${typeId}/attributes?${urlParams.toString()}`);
};

export const fetchOptions = (typeId) =>
  authAxios().get(`/vehicleTypes/${typeId}/attributes/options`);

export const fetchMetadata = (typeId) =>
  authAxios().get(`/vehicleTypes/${typeId}/attributes/metadata`);

export const create = (typeId, data) =>
  authAxios().post(`/vehicleTypes/${typeId}/attributes`, data);

export const fetchById = (typeId, id) =>
  authAxios().get(`/vehicleTypes/${typeId}/attributes/${id}`);

export const deleteById = (typeId, id) =>
  authAxios().delete(`/vehicleTypes/${typeId}/attributes/${id}`);

export const updateById = (typeId, id, data) =>
  authAxios().post(`/vehicleTypes/${typeId}/attributes/${id}?_method=PUT`, data);

export const fetchAttributesFor = async (
  vehicle,
  condition = undefined,
  category = undefined,
  orderByRequired = false
) => {
  const params = {};
  if (condition) params.condition = condition;
  if (category) params.category_id = category;
  if (orderByRequired) params.order_by_required = "1";
  const searchParams = new URLSearchParams(params);
  const url = `attribute/for/${vehicle}?${searchParams.toString()}`;
  return authAxios().get(url);
};
