import { Grid, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";

// eslint-disable-next-line react/prop-types
function TextRow({ title, code, value, error, touched, handleChange, handleBlur, isRequired }) {
  const isRequiredNormalized = isRequired ?? false;
  return (
    <Grid item xs={12} sm={6}>
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {title}
        {isRequiredNormalized && <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <TextField
        variant="outlined"
        fullWidth
        id={code}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={error && touched}
        helperText={error}
      />
    </Grid>
  );
}

export default TextRow;
