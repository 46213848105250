/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React pages
import Login from "pages/Login";
import Dashboard from "pages/Dashboard";
import VehicleTypes from "pages/Dashboard/VehicleTypes";
import VehicleTypeForm from "pages/Dashboard/VehicleTypes/Form";
import Brands from "pages/Dashboard/Brands";
import BrandForm from "pages/Dashboard/Brands/Form";
import VehicleConditionForm from "pages/Dashboard/VehicleConditions/Form";
import VehicleConditions from "pages/Dashboard/VehicleConditions";
import VehicleCategoryForm from "pages/Dashboard/VehicleCategories/Form";
import VehicleCategories from "pages/Dashboard/VehicleCategories";
import SettingForm from "pages/Dashboard/Settings/Form";
import Settings from "pages/Dashboard/Settings";
import RegionForm from "pages/Dashboard/Regions/Form";
import Regions from "pages/Dashboard/Regions";
import Cities from "pages/Dashboard/Cities";
import CityForm from "pages/Dashboard/Cities/Form";
import Services from "pages/Dashboard/Services";
import ServiceForm from "pages/Dashboard/Services/Form";
import Users from "pages/Dashboard/Users";
import UserForm from "pages/Dashboard/Users/Form";
import ChangePasswordForm from "pages/Dashboard/Users/ChangePasswordForm";
import Showrooms from "pages/Dashboard/Showrooms";
import ShowroomForm from "pages/Dashboard/Showrooms/Form";
import ServiceCenters from "pages/Dashboard/ServiceCenters";
import ServiceCenterForm from "pages/Dashboard/ServiceCenters/Form";
import ShowroomBranches from "pages/Dashboard/Showrooms/Branches";
import ShowroomBranchForm from "pages/Dashboard/Showrooms/Branches/Form";
import Articles from "pages/Dashboard/Articles";
import ArticleForm from "pages/Dashboard/Articles/Form";
import Banners from "pages/Dashboard/Banners";
import BannerForm from "pages/Dashboard/Banners/Form";
import VehicleModels from "pages/Dashboard/VehicleModels";
import VehicleModelForm from "pages/Dashboard/VehicleModels/Form";
import VehicleVariants from "pages/Dashboard/VehicleVariants";
import VehicleVariantForm from "pages/Dashboard/VehicleVariants/Form";
import Attributes from "pages/Dashboard/Attributes";
import AttributeForm from "pages/Dashboard/Attributes/Form";
import VehicleClasses from "pages/Dashboard/VehicleClasses";
import VehicleClassForm from "pages/Dashboard/VehicleClasses/Form";
import VehicleEntries from "pages/Dashboard/VehicleEntries";
import VehicleEntryForm from "pages/Dashboard/VehicleEntries/Form";

const createCrudRoutes = (
  roles,
  singleName,
  pluralName,
  indexComponent,
  formComponent,
  inMenu,
  iconName,
  baseRoute,
  replace,
  exact,
  isCondition = false
) => {
  const singleNormalName = singleName
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
  // const pluralNormalName = pluralName
  //   .replace(/([A-Z])/g, " $1")
  //   .replace(/^./, (str) => str.toUpperCase());
  // console.log("🪵 [routes.js:100] ~ token ~ \x1b[0;32mpluralNormalName\x1b[0m = ", pluralNormalName);
  const route = baseRoute || `/dashboard/${singleName}/`;
  return [
    {
      roles,
      type: inMenu ? "collapse" : "hide",
      icon: iconName ? <Icon fontSize="small">{iconName}</Icon> : "",
      name: pluralName,
      key: `dashboard-${singleName}`,
      route,
      replace,
      exact,
      isCondition,
      component: indexComponent,
      meta: {
        auth: true,
      },
    },
    {
      roles,
      type: "hide",
      name: `${singleNormalName} Add`,
      key: `dashboard-${singleName}-add`,
      route: `${route}new/`,
      component: formComponent,
      exact,
      meta: {
        auth: true,
      },
    },
    {
      roles,
      type: "hide",
      name: `${singleNormalName} Edit`,
      key: `dashboard-${singleName}-edit`,
      route: `${route}edit/:id`,
      component: formComponent,
      exact,
      meta: {
        auth: true,
      },
    },
  ];
};

/**
 * PLEASE NOTE: the name property will be used as access to the locales translation while rendering so make sure the name exists in the translations
 */
const routes = [
  {
    type: "collapse",
    name: "dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard/",
    exact: true,
    roles: ["admin", "showroom-admin"],
    component: <Dashboard />,
    meta: {
      auth: true,
    },
  },
  {
    type: "head",
    variant: "type",
    name: "vehicles",
    roles: ["admin", "showroom-admin"],
    key: "vehicles-head",
    icon: <Icon fontSize="small">directions_car</Icon>,
    collapse: [
      ...createCrudRoutes(
        ["admin"],
        "attribute",
        "attributes",
        <Attributes />,
        <AttributeForm />,
        true,
        "",
        "/dashboard/attribute/:typeId/",
        "typeId"
      ),
      ...createCrudRoutes(
        ["admin"],
        "vehicleModel",
        "models",
        <VehicleModels />,
        <VehicleModelForm />,
        true,
        "",
        "/dashboard/vehicleModel/:typeId/",
        "typeId"
      ),
      ...createCrudRoutes(
        ["admin"],
        "vehicleVariant",
        "variants",
        <VehicleVariants />,
        <VehicleVariantForm />,
        true,
        "",
        "/dashboard/vehicleVariant/:typeId/",
        "typeId"
      ),
      ...createCrudRoutes(
        ["admin"],
        "vehicleClass",
        "classes",
        <VehicleClasses />,
        <VehicleClassForm />,
        true,
        "",
        "/dashboard/vehicleClass/:typeId/",
        "typeId"
      ),
      ...createCrudRoutes(
        ["admin", "showroom-admin"],
        "vehicleEntry",
        "entries",
        <VehicleEntries />,
        <VehicleEntryForm />,
        true,
        "",
        "/dashboard/vehicleEntry/:typeId/:conditionId/",
        "typeId",
        null,
        true
      ),
    ],
  },
  {
    type: "head",
    roles: ["admin"],
    name: "entitiesManagement",
    key: "entities-management-head",
    icon: <Icon fontSize="small">person</Icon>,
    collapse: [
      ...createCrudRoutes(["admin"], "user", "users", <Users />, <UserForm />, true),
      {
        roles: ["admin"],
        type: "hide",
        name: "userChangePassword",
        key: "dashboard-users-change-password",
        route: "/dashboard/user/changePassword/:id",
        component: <ChangePasswordForm />,
        meta: {
          auth: true,
        },
      },
      ...createCrudRoutes(
        ["admin"],
        "showroom",
        "showrooms",
        <Showrooms />,
        <ShowroomForm />,
        true
      ),
      ...createCrudRoutes(
        ["admin", "showroom-admin"],
        "showroomBranch",
        "showroomBranches",
        <ShowroomBranches />,
        <ShowroomBranchForm />,
        false,
        "",
        "/dashboard/showroom/:showroomId/branch/"
      ),
      ...createCrudRoutes(
        ["admin"],
        "serviceCenter",
        "serviceCenters",
        <ServiceCenters />,
        <ServiceCenterForm />,
        true
      ),
    ],
  },
  ...createCrudRoutes(
    ["showroom-admin"],
    "showroomBranch",
    "showroomBranches",
    <ShowroomBranches />,
    <ShowroomBranchForm />,
    true,
    "store",
    "/dashboard/showroom/:showroomId/branch/",
    "showroomId"
  ),
  {
    roles: ["admin"],
    type: "head",
    name: "vehicleConfiguration",
    key: "vehicle-configuration-head",
    icon: <Icon fontSize="small">commute</Icon>,
    collapse: [
      ...createCrudRoutes(["admin"], "brand", "brands", <Brands />, <BrandForm />, true),
      ...createCrudRoutes(["admin"], "type", "types", <VehicleTypes />, <VehicleTypeForm />, true),
      ...createCrudRoutes(
        ["admin"],
        "condition",
        "conditions",
        <VehicleConditions />,
        <VehicleConditionForm />,
        true
      ),
      ...createCrudRoutes(
        ["admin"],
        "category",
        "categories",
        <VehicleCategories />,
        <VehicleCategoryForm />,
        true
      ),
    ],
  },
  {
    roles: ["admin"],
    type: "head",
    name: "contentManagment",
    key: "content-management-head",
    icon: <Icon fontSize="small">book</Icon>,
    collapse: [
      ...createCrudRoutes(["admin"], "article", "articles", <Articles />, <ArticleForm />, true),
      ...createCrudRoutes(["admin"], "banner", "banners", <Banners />, <BannerForm />, true),
    ],
  },
  {
    roles: ["admin"],
    type: "head",
    name: "generalConfiguration",
    key: "general-configuration-head",
    icon: <Icon fontSize="small">settings</Icon>,
    collapse: [
      ...createCrudRoutes(["admin"], "region", "regions", <Regions />, <RegionForm />, true),
      ...createCrudRoutes(["admin"], "city", "cities", <Cities />, <CityForm />, true),
      ...createCrudRoutes(
        ["admin"],
        "service",
        "services",
        <Services />,
        <ServiceForm />,
        true,
        null,
        null,
        null,
        true
      ),
      ...createCrudRoutes(["admin"], "setting", "settings", <Settings />, <SettingForm />, true),
    ],
  },
  {
    roles: ["admin", "showroom-admin", "user"],
    type: "hide",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <Login />,
    meta: {
      auth: false,
    },
  },
];

export default routes;
