import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/settings?page=${page}`);

export const create = (data) => authAxios().post(`/settings`, data);

export const fetchById = (id) => authAxios().get(`/settings/${id}`);

export const deleteById = (id) => authAxios().delete(`/settings/${id}`);

export const updateById = (id, data) => authAxios().post(`/settings/${id}?_method=PUT`, data);
