import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import { useMaterialUIController } from "context";
import switchLocale from "util/switchLocale";

export default function Locales() {
  const [controller] = useMaterialUIController();
  const { direction } = controller;
  const handleChange = (event) => {
    switchLocale(event.target.value);
  };

  return (
    <Box sx={{ width: "90%", m: 4, mx: "auto" }}>
      <Select
        sx={{ width: "100%", m: 1, mx: "auto", backgroundColor: "Menu" }}
        id="demo-simple-select"
        value={direction === "ltr" ? "en" : "ar"}
        onChange={handleChange}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="ar">عربي</MenuItem>
      </Select>
    </Box>
  );
}
