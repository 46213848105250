import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function ChangePasswordFormik() {
  const locale = useLocale();
  const { handleSubmit, handleChange, handleBlur, values, errors, touched, isSubmitting } =
    useFormikContext();

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("password")}
          </MDTypography>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            id="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.password && touched.password}
            helperText={errors.password}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("confirm_password")}
          </MDTypography>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            id="confirmPassword"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.confirmPassword && touched.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default ChangePasswordFormik;
