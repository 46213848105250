import axios from "axios";

export const unauthAxios = () =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

export const authAxios = () => {
  const lang = localStorage.getItem("romotors_web_lang") || "en";
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
      accept: "application/json",
      "Accept-Language": lang,
    },
  });
};
