// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/Attributes";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";
import { Formik } from "formik";
import * as Yup from "yup";
import compressImage from "util/compress";
import AttributeFormik from "./form";

function AttributeForm() {
  const { id, typeId } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("title_ar", values.titleAr);
    form.append("title_en", values.titleEn);
    if (values.unitAr && values.unitEn) {
      form.append("unit_ar", values.unitAr);
      form.append("unit_en", values.unitEn);
    }
    if (values.icon instanceof File) {
      form.append("icon", await compressImage(values.icon), values.icon.name);
    } else if (typeof values.icon === "object" && values.icon.file instanceof File) {
      form.append("icon", await compressImage(values.icon.file), values.icon.name);
    } else {
      form.append("icon", values.icon);
    }
    form.append("code", values.code);
    form.append("input_type", values.inputType);
    form.append("required", values.required ? 1 : 0);
    form.append("is_filterable", values.isFilterable ? 1 : 0);
    form.append("is_sortable", values.isSortable ? 1 : 0);
    values.conditions.forEach((conditionId) => {
      form.append("vehicle_condition_ids[]", conditionId);
    });
    if (values.inputType === "select" || values.inputType === "multiselect") {
      values.options.forEach((option, index) => {
        form.append(`options[${index}][code]`, option.code);
        form.append(`options[${index}][value_ar]`, option.ar);
        form.append(`options[${index}][value_en]`, option.en);
      });
    }
    if (id) {
      updateById(typeId, id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} attribute has been updated`);
          setTimeout(() => {
            navigate(`/dashboard/attribute/${typeId}/`);
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      create(typeId, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} attribute has been created`);
          setTimeout(() => {
            navigate(`/dashboard/attribute/${typeId}/`);
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Attributes Form
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    titleEn: "",
                    titleAr: "",
                    unitEn: "",
                    unitAr: "",
                    icon: "",
                    code: "",
                    required: false,
                    isFilterable: false,
                    isSortable: false,
                    inputType: "",
                    conditions: [],
                    options: [],
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    titleEn: Yup.string().required("Field is required"),
                    titleAr: Yup.string().required("Field is required"),
                    unitEn: Yup.string().notRequired(),
                    unitAr: Yup.string().notRequired(),
                    icon: Yup.mixed().required("Image is required"),
                    code: Yup.string().required("Field is required"),
                    required: Yup.bool().required("Field is required"),
                    isFilterable: Yup.bool().required("Field is required"),
                    isSortable: Yup.bool().required("Field is required"),
                    inputType: Yup.string().required("Field is required"),
                  })}
                >
                  <AttributeFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default AttributeForm;
