import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";

// eslint-disable-next-line react/prop-types
function CheckboxRow({ title, code, value, error, touched, handleChange, handleBlur, isRequired }) {
  const isRequiredNormalized = isRequired ?? false;

  return (
    <Grid item xs={12} sm={6}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              name={code}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error && touched}
            />
          }
          label={
            <MDTypography component="p" variant="button" color="text" fontWeight="medium">
              {title}
              {isRequiredNormalized && <span style={{ color: "red" }}>*</span>}
            </MDTypography>
          }
        />
      </FormGroup>
    </Grid>
  );
}

export default CheckboxRow;
