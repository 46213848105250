/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// @mui material components
import { Dialog, DialogActions, DialogTitle, Icon, IconButton } from "@mui/material";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setSuccessSB, setErrorSB } from "context";
import useLocale from "context/useLocale";
import { useState, useEffect } from "react";
import Moment from "react-moment";

import { fetchAll, deleteById } from "services/Users";

export default function data() {
  const locale = useLocale();
  const [, dispatch] = useMaterialUIController();
  const [rows, setRows] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState({});
  const [open, setOpen] = useState([]);

  const handleClickOpen = (id) => {
    const openCopy = [...open];
    openCopy[id] = true;
    setOpen(openCopy);
  };

  const handleClose = (id) => {
    const openCopy = [...open];
    openCopy[id] = false;
    setOpen(openCopy);
  };

  const loadData = (page) => {
    fetchAll(page).then(
      (res) => {
        setRows(res.data.data.data);
        setPaginationMeta(res.data.data.meta.pagination);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)(err)
    );
  };

  useEffect(() => {
    loadData(1);
  }, []);

  const handleDelete = (id) => {
    deleteById(id).then(
      (res) => {
        loadData();
        setSuccessSB(dispatch, `${res.data.data.name} user has been removed`);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    handleClose(id);
  };

  const renderRows = rows.map((row) => ({
    id: row.id,
    name: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {row.name}
      </MDTypography>
    ),
    // email: (
    //   <MDTypography component="p" variant="button" color="text" fontWeight="medium">
    //     {row.email}
    //   </MDTypography>
    // ),
    phoneNumber: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {row.phone_number}
      </MDTypography>
    ),
    created_at: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        <Moment format="DD-MMM-YYYY, hh:mm A">{row.created_at}</Moment>
      </MDTypography>
    ),
    updated_at: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        <Moment format="DD-MMM-YYYY, hh:mm A">{row.updated_at}</Moment>
      </MDTypography>
    ),
    action: (
      <>
        <IconButton aria-label="edit" color="blue" href={`edit/${row.id}`}>
          <Icon fontSize="sm">edit</Icon>
        </IconButton>
        <IconButton aria-label="edit" color="warning" href={`changePassword/${row.id}`}>
          <Icon fontSize="sm">lock</Icon>
        </IconButton>
        <IconButton aria-label="delete" color="primary" onClick={() => handleClickOpen(row.id)}>
          <Icon fontSize="sm">delete</Icon>
        </IconButton>
        <Dialog
          open={typeof open[row.id] !== "undefined" && open[row.id]}
          onClose={() => handleClose(row.id)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you want to delete user (${row.name}) ?`}
          </DialogTitle>
          <DialogActions>
            <MDButton variant="contained" onClick={() => handleClose(row.id)}>
              {locale("cancel")}
            </MDButton>
            <MDButton variant="contained" color="error" onClick={() => handleDelete(row.id)}>
              {locale("confirm")}
            </MDButton>
          </DialogActions>
        </Dialog>
      </>
    ),
  }));

  return {
    columns: [
      { Header: locale("id"), accessor: "id" },
      { Header: locale("name"), accessor: "name" },
      // { Header: locale("email"), accessor: "email" },
      { Header: locale("phone"), accessor: "phoneNumber" },
      { Header: locale("createdAt"), accessor: "created_at" },
      { Header: locale("updatedAt"), accessor: "updated_at" },
      { Header: locale("actions"), accessor: "action", align: "left" },
    ],
    rows: renderRows,
    paginationMeta,
    loadData,
  };
}
