import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Articles";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { stateToHTML } from "draft-js-export-html";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import MDAvatar from "components/MDAvatar";
import useLocale from "context/useLocale";

function ArticleFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const [imagePreview, setImagePreview] = useState();
  const [topicArEditorState, setTopicArEditorState] = useState();
  const [topicEnEditorState, setTopicEnEditorState] = useState();

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.image, setImagePreview);
  }, [values.image]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  const editorStylingObject = {
    border: "1px solid #F1F1F1",
  };

  const getContentState = (html) => {
    const blocksFromHTML = convertFromHTML(html);
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
  };

  useEffect(async () => {
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("image", res.data.data.image);
          setFieldValue("topicEn", res.data.data.topic.en);
          setFieldValue("topicAr", res.data.data.topic.ar);
          setTopicEnEditorState(
            EditorState.createWithContent(getContentState(res.data.data.topic.en))
          );
          setTopicArEditorState(
            EditorState.createWithContent(getContentState(res.data.data.topic.ar))
          );
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  const handleEnEditorChange = (state) => {
    setTopicEnEditorState(state);
    setFieldValue("topicEn", stateToHTML(topicEnEditorState.getCurrentContent()));
  };

  const handleArEditorChange = (state) => {
    setTopicArEditorState(state);
    setFieldValue("topicAr", stateToHTML(topicArEditorState.getCurrentContent()));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("image")}
          </MDTypography>
          {imagePreview && <MDAvatar src={imagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="image"
            name="image"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "image");
            }}
            onBlur={handleBlur}
            error={errors.image && touched.image}
            helperText={errors.image}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("topicEn")}
          </MDTypography>
          <Editor
            id="topicEn"
            name="topicEn"
            editorStyle={editorStylingObject}
            editorState={topicEnEditorState}
            onEditorStateChange={handleEnEditorChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("topicAr")}
          </MDTypography>
          <Editor
            id="topicAr"
            name="topicAr"
            editorStyle={editorStylingObject}
            editorState={topicArEditorState}
            onEditorStateChange={handleArEditorChange}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default ArticleFormik;
