// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { fetchById, updatePasswordById } from "services/Users";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";
import { Formik } from "formik";
import * as Yup from "yup";
import useLocale from "context/useLocale";
import ChangePasswordFormik from "./form";

function ChangePasswordForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const [user, setUser] = useState({});

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("password", values.password);
    form.append("password_confirmation", values.confirmPassword);
    updatePasswordById(id, form).then(
      (res) => {
        setSuccessSB(dispatch, `${res.data.data.name} password has been updated`);
        setTimeout(() => {
          navigate("/dashboard/user/");
        }, 500);
      },
      (err) =>
        setErrorSB(
          dispatch,
          err.response.data.message ? err.response.data.message : err.response.data.error.message
        )
    );
  };

  useEffect(async () => {
    await fetchById(id).then(
      (res) => {
        setUser(res.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("changePasswordForm")} ({user.name})
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    password: Yup.string().required("Field is required").min(6),
                    confirmPassword: Yup.string()
                      .required("Field is required")
                      .oneOf([Yup.ref("password"), null], "Passwords must match"),
                  })}
                >
                  <ChangePasswordFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ChangePasswordForm;
