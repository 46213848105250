// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/VehicleClasses";
import { fetchMetadata } from "services/Attributes";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";

import { Formik } from "formik";
import * as Yup from "yup";
import compressImage from "util/compress";
import useLocale from "context/useLocale";
import VehicleClassFormik from "./form";

function VehicleClassForm() {
  const locale = useLocale();
  const { id, typeId } = useParams();
  const [metadata, setMetadata] = useState({});
  const [updatedInitialValues, setUpdatedInitialValues] = useState({});
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState({});
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const initialValues = {
    titleEn: "",
    titleAr: "",
    mainImage: "",
    brandId: "",
    vehicleModelId: "",
    vehicleVariantId: "",
    price: "",
    priceReleaseDate: "",
    gallery: [],
  };

  const validationSchema = {
    titleEn: Yup.string().required("Field is required"),
    titleAr: Yup.string().required("Field is required"),
    mainImage: Yup.mixed().required("Image is required"),
    brandId: Yup.string().required("Field is required"),
    vehicleModelId: Yup.string().required("Field is required"),
    vehicleVariantId: Yup.string().required("Field is required"),
    price: Yup.number("Must be a number").required("Field is required"),
    priceReleaseDate: Yup.date().required("Field is required"),
    gallery: Yup.array().notRequired(),
  };

  const getInitialValues = () =>
    Object.keys(updatedInitialValues).length ? updatedInitialValues : initialValues;
  const getValidationSchema = () =>
    Object.keys(updatedValidationSchema).length ? updatedValidationSchema : validationSchema;

  useEffect(async () => {
    fetchMetadata(typeId).then(
      (res) => {
        const initialValuesCopy = { ...initialValues };
        const validationSchemaCopy = { ...validationSchema };
        res.data.data.data.forEach((attribute) => {
          if (attribute.input_type === "checkbox") {
            initialValuesCopy[attribute.code] = false;
          } else {
            initialValuesCopy[attribute.code] = "";
          }
          if (attribute.isRequired) {
            validationSchema[attribute.code] = Yup.string().required("Field is required");
          } else {
            validationSchema[attribute.code] = Yup.string().notRequired();
          }
        });
        setUpdatedInitialValues(initialValuesCopy);
        setUpdatedValidationSchema(validationSchemaCopy);
        setMetadata(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  }, []);

  useEffect(() => {
    if (Object.keys(updatedInitialValues).length && Object.keys(updatedValidationSchema).length) {
      setLoading(false);
    }
  }, [updatedInitialValues, updatedValidationSchema]);

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("title_ar", values.titleAr);
    form.append("title_en", values.titleEn);
    if (values.mainImage instanceof File) {
      form.append("main_image", await compressImage(values.mainImage), values.mainImage.name);
    } else {
      form.append("main_image", values.mainImage);
    }
    form.append("vehicle_variant_id", values.vehicleVariantId);
    form.append("price", values.price);
    form.append("price_release_date", values.priceReleaseDate.format("YYYY-MM-DD HH:mm:ss"));
    metadata.forEach((attribute) => {
      if (attribute.input_type === "checkbox") {
        form.append(`attribute_values[${attribute.id}]`, values[attribute.code] ? 1 : 0);
      } else {
        form.append(`attribute_values[${attribute.id}]`, values[attribute.code]);
      }
    });
    await Promise.all(
      values.gallery.map(async (image) => {
        if (image instanceof File) {
          form.append("gallery[]", await compressImage(image), image.name);
        } else {
          form.append("gallery[]", image);
        }
      })
    );
    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} class has been updated`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleClass/${typeId}/`);
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} class has been created`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleClass/${typeId}/`);
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("vehicleClassesForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                {!loading && (
                  <Formik
                    initialValues={getInitialValues()}
                    onSubmit={async (values) => {
                      await submitForm(values);
                    }}
                    validationSchema={Yup.object().shape(getValidationSchema())}
                  >
                    <VehicleClassFormik metadata={metadata} />
                  </Formik>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default VehicleClassForm;
