import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/banners?page=${page}`);

export const create = (data) => authAxios().post(`/banners`, data);

export const fetchById = (id) => authAxios().get(`/banners/${id}`);

export const deleteById = (id) => authAxios().delete(`/banners/${id}`);

export const updateById = (id, data) => authAxios().post(`/banners/${id}?_method=PUT`, data);
