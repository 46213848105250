// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/VehicleVariants";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";

import { Formik } from "formik";
import * as Yup from "yup";
import useLocale from "context/useLocale";
import compressImage from "util/compress";
import VehicleVariantFormik from "./form";

function VehicleVariantForm() {
  const locale = useLocale();
  const { id, typeId } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("title_ar", values.titleAr);
    form.append("title_en", values.titleEn);
    if (values.mainImage instanceof File) {
      form.append("main_image", await compressImage(values.mainImage), values.mainImage.name);
    } else if (typeof values.mainImage === "object" && values.mainImage.file instanceof File) {
      form.append("icon", await compressImage(values.mainImage.file), values.mainImage.name);
    } else {
      form.append("main_image", values.mainImage);
    }
    form.append("vehicle_model_id", values.vehicleModelId);
    form.append("year", values.year);
    form.append("show_as_new", values.showAsNew ? 1 : 0);
    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} variant has been updated`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleVariant/${typeId}/`);
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} variant has been created`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleVariant/${typeId}/`);
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("variantsForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    titleEn: "",
                    titleAr: "",
                    mainImage: "",
                    vehicleModelId: "",
                    year: "",
                    showAsNew: true,
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    titleEn: Yup.string().required("Field is required"),
                    titleAr: Yup.string().required("Field is required"),
                    mainImage: Yup.mixed().required("Image is required"),
                    vehicleModelId: Yup.string().required("Field is required"),
                    year: Yup.number("Must be a number").required("Field is required"),
                    showAsNew: Yup.bool().required("Field is required"),
                  })}
                >
                  <VehicleVariantFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default VehicleVariantForm;
