import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/ShowroomBranches";
import { fetchOptions as fetchRegionsOptions } from "services/Regions/index";
import { fetchOptions as fetchCitiesOptions } from "services/Cities";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function ShowroomBranchFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { showroomId, id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState(null);

  useEffect(async () => {
    fetchCitiesOptions().then(
      (res) => {
        setCities(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    fetchRegionsOptions().then(
      (res) => {
        setRegions(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(showroomId, id).then(
        (res) => {
          setFieldValue("nameEn", res.data.data.name.en);
          setFieldValue("nameAr", res.data.data.name.ar);
          setFieldValue("addressEn", res.data.data.address.en);
          setFieldValue("addressAr", res.data.data.address.ar);
          setFieldValue("latitude", res.data.data.latitude ?? "");
          setFieldValue("longitude", res.data.data.longitude ?? "");
          setFieldValue("phoneNumber", res.data.data.phone_number);
          setFieldValue("mapLink", res.data.data.map_link);
          setFieldValue("regionId", res.data.data.region.id);
          setFieldValue("cityId", res.data.data.region.city.id);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  useEffect(() => {
    // Fetch regions when cityId changes
    if (cityId) {
      fetchRegionsOptions(cityId).then(
        (res) => {
          setRegions(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, [cityId]);

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    setCityId(selectedCityId);
    setFieldValue("cityId", selectedCityId);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameEn"
            value={values.nameEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameEn && touched.nameEn}
            helperText={errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameAr"
            value={values.nameAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameAr && touched.nameAr}
            helperText={errors.nameAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("addressEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="addressEn"
            value={values.addressEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.addressEn && touched.addressEn}
            helperText={errors.addressEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("addressAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="addressAr"
            value={values.addressAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.addressAr && touched.addressAr}
            helperText={errors.addressAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("mapLink")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="mapLink"
            value={values.mapLink}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.mapLink && touched.mapLink}
            helperText={errors.mapLink}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("phone")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("latitude")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="latitude"
            value={values.latitude}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.latitude && touched.latitude}
            helperText={errors.latitude}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("longitude")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="longitude"
            value={values.longitude}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.longitude && touched.longitude}
            helperText={errors.longitude}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("city")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="cityId"
              name="cityId"
              onChange={handleCityChange}
              onBlur={handleBlur}
              value={values.cityId}
              error={errors.cityId && touched.cityId}
            >
              {cities.map((city) => (
                <MenuItem key={city.value} value={city.value}>
                  {city.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.cityId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("region")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="regionId"
              name="regionId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.regionId}
              error={errors.regionId && touched.regionId}
            >
              {regions.map((region) => (
                <MenuItem key={region.value} value={region.value}>
                  {region.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.regionId}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default ShowroomBranchFormik;
