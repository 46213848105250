import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/VehicleClasses";
import { fetchOptions as fetchModelOptions } from "services/VehicleModels";
import { fetchOptions as fetchBrandOptions } from "services/Brands";
import { fetchOptions as fetchVariantOptions } from "services/VehicleVariants";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import PropTypes from "prop-types";
import TextRow from "components/Fields/TextRow";
import SelectRow from "components/Fields/SelectRow";
import CheckboxRow from "components/Fields/CheckboxRow";
import { DropzoneArea } from "react-mui-dropzone";
import useLocale from "context/useLocale";

function VehicleClassFormik({ metadata }) {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id, typeId } = useParams();
  const [mainImagePreview, setMainImagePreview] = useState();
  const [vehicleModels, setVehicleModels] = useState([]);
  const [brands, setBrands] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, dispatch] = useMaterialUIController();

  const handleGalleryChange = (files) => {
    setFieldValue("gallery", files);
  };

  const getGallery = () =>
    values.gallery.map((image) => `${process.env.REACT_APP_BACKEND_URL}/storage/${image}`);

  const handleDateChange = (value) => {
    setFieldValue("priceReleaseDate", value);
  };

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.mainImage, setMainImagePreview);
  }, [values.mainImage]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  const fetchVariants = (vehicleModelId, variantId = null) => {
    fetchVariantOptions({
      vehicle_model_id: vehicleModelId,
    }).then(
      (res) => {
        setVehicleVariants(res.data.data.data);
        if (variantId) {
          setFieldValue("vehicleVariantId", variantId);
        }
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  const handleVehicleModelChange = (value) => {
    setFieldValue("vehicleModelId", value);
    setFieldValue("vehicleVariantId", "");
    fetchVariants(value);
  };

  const handleBrandChange = (value) => {
    setFieldValue("brandId", value);
    fetchModelOptions({
      vehicle_type_id: typeId,
      brand_id: value,
    }).then(
      (res) => {
        setVehicleModels(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  useEffect(async () => {
    fetchBrandOptions({
      type_id: typeId,
    }).then(
      (res) => {
        setBrands(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("mainImage", res.data.data.main_image);
          setFieldValue("brandId", res.data.data.vehicle_variant.vehicle_model.brand.id);
          setFieldValue("vehicleModelId", res.data.data.vehicle_variant.vehicle_model.id);
          setFieldValue("price", res.data.data.price);
          setFieldValue("priceReleaseDate", moment(res.data.data.price_release_date));
          res.data.data.attribute_values.forEach((attributeValue) => {
            if (attributeValue.attribute.input_type === "checkbox") {
              setFieldValue(attributeValue.attribute.code, attributeValue.value === "1");
            } else {
              setFieldValue(attributeValue.attribute.code, attributeValue.value);
            }
          });
          fetchModelOptions({
            vehicle_type_id: typeId,
            brand_id: res.data.data.vehicle_variant.vehicle_model.brand.id,
          }).then(
            (result) => {
              setVehicleModels(result.data.data.data);
            },
            (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
          );
          fetchVariants(
            res.data.data.vehicle_variant.vehicle_model.id,
            res.data.data.vehicle_variant.id
          );
          setFieldValue(
            "gallery",
            res.data.data.images.map((image) => image.image)
          );
          setLoading(false);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("brand")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="brandId"
              name="brandId"
              onChange={(e) => {
                handleBrandChange(e.target.value);
              }}
              onBlur={handleBlur}
              value={values.brandId}
              error={errors.brandId && touched.brandId}
            >
              {brands.map((brand) => (
                <MenuItem key={brand.value} value={brand.value}>
                  {brand.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.brandId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("model")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="vehicleModelId"
              name="vehicleModelId"
              onChange={(e) => {
                handleVehicleModelChange(e.target.value);
              }}
              onBlur={handleBlur}
              value={values.vehicleModelId}
              error={errors.vehicleModelId && touched.vehicleModelId}
            >
              {vehicleModels.map((vehicleModel) => (
                <MenuItem key={vehicleModel.value} value={vehicleModel.value}>
                  {vehicleModel.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.vehicleModelId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("variant")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="vehicleVariantId"
              name="vehicleVariantId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vehicleVariantId}
              error={errors.vehicleVariantId && touched.vehicleVariantId}
            >
              {vehicleVariants.map((vehicleVariant) => (
                <MenuItem key={vehicleVariant.value} value={vehicleVariant.value}>
                  {vehicleVariant.label} - {vehicleVariant.year}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.vehicleVariantId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("mainImage")}
          </MDTypography>
          {mainImagePreview && <MDAvatar src={mainImagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="mainImage"
            name="mainImage"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "mainImage");
            }}
            onBlur={handleBlur}
            error={errors.mainImage && touched.mainImage}
            helperText={errors.mainImage}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("gallery")}
          </MDTypography>
          {!loading && <DropzoneArea initialFiles={getGallery()} onChange={handleGalleryChange} />}
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("price")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="price"
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.price && touched.price}
            helperText={errors.price}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("priceReleaseDay")}
          </MDTypography>
          <DesktopDatePicker
            inputFormat="MM/DD/YYYY"
            value={values.priceReleaseDate}
            onChange={handleDateChange}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                fullWidth
                id="priceReleaseDate"
                onBlur={handleBlur}
                {...params}
                error={errors.priceReleaseDate && touched.priceReleaseDate}
                helperText={errors.priceReleaseDate}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("customAttributes")}
          </MDTypography>
        </Grid>
        {metadata.map((attribute) => {
          if (attribute.input_type === "text") {
            return (
              <TextRow
                key={attribute.id}
                title={attribute.title}
                code={attribute.code}
                value={values[attribute.code]}
                error={errors[attribute.code]}
                touched={touched[attribute.code]}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            );
          }
          if (attribute.input_type === "select" || attribute.input_type === "multiselect") {
            return (
              <SelectRow
                key={attribute.id}
                title={attribute.title}
                code={attribute.code}
                value={values[attribute.code]}
                error={errors[attribute.code]}
                touched={touched[attribute.code]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                options={attribute.options}
                multiselect={attribute.input_type !== "select"}
              />
            );
          }
          return "";
        })}
        {metadata.map((attribute) => {
          if (attribute.input_type === "checkbox") {
            return (
              <CheckboxRow
                key={attribute.id}
                title={attribute.title}
                code={attribute.code}
                value={values[attribute.code]}
                error={errors[attribute.code]}
                touched={touched[attribute.code]}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            );
          }
          return "";
        })}
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

// Typechecking props for the Footer
VehicleClassFormik.propTypes = {
  metadata: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default VehicleClassFormik;
