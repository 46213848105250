// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/Banners";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";

import { Formik } from "formik";
import * as Yup from "yup";
import useLocale from "context/useLocale";
import compressImage from "util/compress";
import BannerFormik from "./form";

function BannerForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("name_ar", values.nameAr);
    form.append("name_en", values.nameEn);
    form.append("title_ar", values.titleAr);
    form.append("title_en", values.titleEn);
    form.append("subtitle_ar", values.subtitleAr);
    form.append("subtitle_en", values.subtitleEn);
    if (values.image instanceof File) {
      form.append("image", await compressImage(values.image), values.image.name);
    } else {
      form.append("image", values.image);
    }
    form.append("link", values.link);
    form.append("place", values.place);
    if (values.showroomId !== "") {
      form.append("showroom_id", values.showroomId);
    }
    if (values.serviceCenterId !== "") {
      form.append("service_center_id", values.serviceCenterId);
    }
    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name.en} banner has been updated`);
          setTimeout(() => {
            navigate("/dashboard/banner/");
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name.en} banner has been created`);
          setTimeout(() => {
            navigate("/dashboard/banner/");
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("bannersForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    nameEn: "",
                    nameAr: "",
                    titleEn: "",
                    titleAr: "",
                    subtitleEn: "",
                    subtitleAr: "",
                    image: "",
                    link: "",
                    place: "",
                    showroomId: "",
                    serviceCenterId: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    nameEn: Yup.string().required("Field is required"),
                    nameAr: Yup.string().required("Field is required"),
                    titleEn: Yup.string().required("Field is required"),
                    titleAr: Yup.string().required("Field is required"),
                    subtitleEn: Yup.string().required("Field is required"),
                    subtitleAr: Yup.string().required("Field is required"),
                    image: Yup.mixed().required("Image is required"),
                    link: Yup.string().notRequired(),
                    place: Yup.string().notRequired(),
                    showroomId: Yup.string().notRequired(),
                    serviceCenterId: Yup.string().notRequired(),
                  })}
                >
                  <BannerFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default BannerForm;
