import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/vehicleConditions?page=${page}`);

export const fetchOptions = () => authAxios().get(`/vehicleConditions/options`);

export const create = (data) => authAxios().post(`/vehicleConditions`, data);

export const fetchById = (id) => authAxios().get(`/vehicleConditions/${id}`);

export const deleteById = (id) => authAxios().delete(`/vehicleConditions/${id}`);

export const updateById = (id, data) =>
  authAxios().post(`/vehicleConditions/${id}?_method=PUT`, data);
