// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/VehicleCategories";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";
import * as Yup from "yup";
import { Formik } from "formik";
import compressImage from "util/compress";
import useLocale from "context/useLocale";
import VehicleCategoryFormik from "./form";

function VehicleCategoryForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("title_ar", values.titleAr);
    form.append("title_en", values.titleEn);
    form.append("code", values.code);
    form.append("vehicle_type_id", values.vehicleTypeId);
    if (values.image instanceof File) {
      form.append("image", await compressImage(values.image), values.image.name);
    } else {
      form.append("image", values.image);
    }
    if (values.parentId) {
      form.append("parent_id", values.parentId);
    }
    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} vehicle category has been updated`);
          setTimeout(() => {
            navigate("/dashboard/category/");
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title.en} vehicle category has been created`);
          setTimeout(() => {
            navigate("/dashboard/category/");
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("vehicleCategoriesForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    vehicleTypeId: "",
                    titleEn: "",
                    titleAr: "",
                    code: "",
                    image: "",
                    parentId: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    vehicleTypeId: Yup.mixed().required("Field is required"),
                    titleEn: Yup.string().required("Field is required"),
                    titleAr: Yup.string().required("Field is required"),
                    code: Yup.string().required("Field is required"),
                    image: Yup.mixed().required("Image is required"),
                    parentId: Yup.string().notRequired(),
                  })}
                >
                  <VehicleCategoryFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default VehicleCategoryForm;
