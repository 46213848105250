import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/services?page=${page}`);

export const fetchOptions = () => authAxios().get(`/services/options`);

export const create = (data) => authAxios().post(`/services`, data);

export const fetchById = (id) => authAxios().get(`/services/${id}`);

export const deleteById = (id) => authAxios().delete(`/services/${id}`);

export const updateById = (id, data) => authAxios().post(`/services/${id}?_method=PUT`, data);
