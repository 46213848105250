/* eslint-disable react/prop-types */
import { FormControl, FormHelperText, Grid, MenuItem, Select } from "@mui/material";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";

function SelectRow({
  title,
  code,
  value,
  error,
  touched,
  handleChange,
  handleBlur,
  options,
  multiple,
  isRequired,
}) {
  const isRequiredNormalized = isRequired ?? false;
  return (
    <Grid item xs={12} sm={6}>
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {title}
        {isRequiredNormalized && <span style={{ color: "red" }}>*</span>}
      </MDTypography>
      <FormControl fullWidth size="large">
        <Select
          id={code}
          name={code}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          error={error && touched}
          multiple={multiple}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Grid>
  );
}

SelectRow.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SelectRow;
