import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/brands?page=${page}`);

export const fetchOptions = (filters = {}) => {
  const adjustedFilters = Object.keys(filters).map((filterIndex) => [
    `filters[${filterIndex}]`,
    filters[filterIndex],
  ]);
  const urlParams = new URLSearchParams(adjustedFilters).toString();
  return authAxios().get(`/brands/options?${urlParams}`);
};

export const create = (data) => authAxios().post(`/brands`, data);

export const fetchById = (id) => authAxios().get(`/brands/${id}`);

export const deleteById = (id) => authAxios().delete(`/brands/${id}`);

export const updateById = (id, data) => authAxios().post(`/brands/${id}?_method=PUT`, data);
