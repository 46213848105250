import { authAxios } from "services";

export const fetchAll = (showroomId, page) =>
  authAxios().get(`/showrooms/${showroomId}/branches?page=${page}`);

export const fetchOptions = (showroomId) =>
  authAxios().get(`/showrooms/${showroomId}/branches/options`);

export const create = (showroomId, data) =>
  authAxios().post(`/showrooms/${showroomId}/branches`, data);

export const fetchById = (showroomId, id) =>
  authAxios().get(`/showrooms/${showroomId}/branches/${id}`);

export const deleteById = (showroomId, id) =>
  authAxios().delete(`/showrooms/${showroomId}/branches/${id}`);

export const updateById = (showroomId, id, data) =>
  authAxios().post(`/showrooms/${showroomId}/branches/${id}?_method=PUT`, data);
