// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/ServiceCenters";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";

import { Formik } from "formik";
import * as Yup from "yup";
import useLocale from "context/useLocale";
import compressImage from "util/compress";
import ServiceCenterFormik from "./form";

function ServiceCenterForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("name_ar", values.nameAr);
    form.append("name_en", values.nameEn);
    form.append("description_ar", values.descriptionAr);
    form.append("description_en", values.descriptionEn);
    form.append("address_ar", values.addressAr);
    form.append("address_en", values.addressEn);
    form.append("working_hours_ar", values.workingHoursAr);
    form.append("working_hours_en", values.workingHoursEn);
    form.append("days_off_ar", values.daysOffAr);
    form.append("days_off_en", values.daysOffEn);
    form.append("region_id", values.regionId);
    form.append("city_id", values.cityId);
    if (values.image instanceof File) {
      form.append("image", await compressImage(values.image), values.image.name);
    } else {
      form.append("image", values.image);
    }
    if (values.cover instanceof File) {
      form.append("cover", await compressImage(values.cover), values.cover.name);
    } else if (values.cover !== "") {
      form.append("cover", values.cover);
    }
    values.brands.forEach((brandId) => {
      form.append("brand_ids[]", brandId);
    });
    values.services.forEach((serviceId) => {
      form.append("service_ids[]", serviceId);
    });
    values.vehicleTypes.forEach((vehicleTypeId) => {
      form.append("vehicle_type_ids[]", vehicleTypeId);
    });
    form.append("website", values.website);
    form.append("youtube", values.youtube);
    form.append("instagram", values.instagram);
    form.append("facebook", values.facebook);
    form.append("maps", values.maps);
    form.append("phone_number", values.phoneNumber);
    if (id) {
      try {
        const res = await updateById(id, form);
        setSuccessSB(dispatch, `${res.data.data.name.en} service center has been updated`);
        setTimeout(() => {
          navigate("/dashboard/serviceCenter/");
        }, 500);
      } catch (err) {
        setErrorSB(
          dispatch,
          err.response.data.message ? err.response.data.message : err.response.data.error.message
        );
      }
    } else {
      try {
        const res = await create(form);
        setSuccessSB(dispatch, `${res.data.data.name.en} service center has been created`);
        setTimeout(() => {
          navigate("/dashboard/serviceCenter/");
        }, 1500);
      } catch (err) {
        setErrorSB(
          dispatch,
          err.response.data.message ? err.response.data.message : err.response.data.error.message
        );
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("serviceCenterForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    nameEn: "",
                    nameAr: "",
                    descriptionEn: "",
                    descriptionAr: "",
                    addressEn: "",
                    addressAr: "",
                    workingHoursEn: "",
                    workingHoursAr: "",
                    daysOffEn: "",
                    daysOffAr: "",
                    brands: [],
                    services: [],
                    vehicleTypes: [],
                    image: "",
                    cover: "",
                    website: "",
                    youtube: "",
                    instagram: "",
                    facebook: "",
                    maps: "",
                    phoneNumber: "",
                    cityId: "",
                    regionId: "",
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    nameEn: Yup.string().required("Field is required"),
                    nameAr: Yup.string().required("Field is required"),
                    descriptionEn: Yup.string().notRequired(),
                    descriptionAr: Yup.string().notRequired(),
                    addressEn: Yup.string().required("Field is required"),
                    addressAr: Yup.string().required("Field is required"),
                    workingHoursEn: Yup.string().required("Field is required"),
                    workingHoursAr: Yup.string().required("Field is required"),
                    daysOffEn: Yup.string().required("Field is required"),
                    daysOffAr: Yup.string().required("Field is required"),
                    image: Yup.mixed().required("Image is required"),
                    cover: Yup.mixed().notRequired(),
                    website: Yup.string().notRequired(),
                    youtube: Yup.string().notRequired(),
                    instagram: Yup.string().notRequired(),
                    facebook: Yup.string().url("Invalid URL").notRequired(),
                    maps: Yup.string().notRequired(),
                    phoneNumber: Yup.string().required("Field is required"),
                    cityId: Yup.string().required("Field is required"),
                    regionId: Yup.string().required("Field is required"),
                  })}
                >
                  <ServiceCenterFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ServiceCenterForm;
