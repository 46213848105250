// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/Showrooms";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";

import { Formik } from "formik";
import * as Yup from "yup";
import compressImage from "util/compress";
import useLocale from "context/useLocale";
import ShowroomFormik from "./form";

function ShowroomForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("name_ar", values.nameAr);
    form.append("name_en", values.nameEn);
    form.append("description_ar", values.descriptionAr);
    form.append("description_en", values.descriptionEn);
    if (values.image instanceof File) {
      form.append("image", await compressImage(values.image), values.image.name);
    } else {
      form.append("image", values.image);
    }
    if (values.cover instanceof File) {
      form.append("cover", await compressImage(values.cover), values.cover.name);
    } else if (values.cover !== "") {
      form.append("cover", values.cover);
    }
    form.append("website", values.website);
    form.append("youtube", values.youtube);
    form.append("instagram", values.instagram);
    form.append("facebook", values.facebook);
    form.append("phone_number", values.phoneNumber);
    form.append("email", values.email);
    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name.en} showroom has been updated`);
          setTimeout(() => {
            navigate("/dashboard/showroom/");
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name.en} showroom has been created`);
          setTimeout(() => {
            navigate("/dashboard/showroom/");
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("showroomsForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    nameEn: "",
                    nameAr: "",
                    descriptionEn: "",
                    descriptionAr: "",
                    image: "",
                    cover: "",
                    website: "",
                    youtube: "",
                    instagram: "",
                    facebook: "",
                    phoneNumber: "",
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    nameEn: Yup.string().required("Field is required"),
                    nameAr: Yup.string().required("Field is required"),
                    descriptionEn: Yup.string().notRequired(),
                    descriptionAr: Yup.string().notRequired(),
                    image: Yup.mixed().required("Image is required"),
                    cover: Yup.mixed().notRequired(),
                    website: Yup.string().notRequired().nullable(),
                    youtube: Yup.string().notRequired().nullable(),
                    instagram: Yup.string().notRequired().nullable(),
                    facebook: Yup.string().notRequired().nullable(),
                    // TODO: add custom validation for egyption phone numbers
                    phoneNumber: Yup.string().required(),
                    email: Yup.string().email().notRequired().nullable(),
                  })}
                >
                  <ShowroomFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default ShowroomForm;
