import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/vehicleCategories?page=${page}`);

export const fetchAllMetaAsTree = (vehicleTypeId) =>
  authAxios().get(`/vehicleCategories/index/tree/${vehicleTypeId}`);

export const fetchOptions = (filters = {}) => {
  const adjustedFilters = Object.keys(filters).map((filterIndex) => [
    `filters[${filterIndex}]`,
    filters[filterIndex] === null ? "" : filters[filterIndex],
  ]);
  const urlParams = new URLSearchParams(adjustedFilters).toString();
  return authAxios().get(`/vehicleCategories/options?${urlParams}`);
};

export const create = (data) => authAxios().post(`/vehicleCategories`, data);

export const fetchById = (id) => authAxios().get(`/vehicleCategories/${id}`);

export const deleteById = (id) => authAxios().delete(`/vehicleCategories/${id}`);

export const updateById = (id, data) =>
  authAxios().post(`/vehicleCategories/${id}?_method=PUT`, data);
