import {
  FormControl,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Users";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import { fetchOptions as fetchShowroomsOptions } from "services/Showrooms";
import useLocale from "context/useLocale";

function UserFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [showrooms, setShowrooms] = useState([]);
  const [, dispatch] = useMaterialUIController();

  useEffect(async () => {
    fetchShowroomsOptions().then(
      (res) => {
        setShowrooms(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("name", res.data.data.name);
          setFieldValue("email", res.data.data.email);
          setFieldValue("phoneNumber", res.data.data.phone_number);
          setFieldValue("role", res.data.data.roles && res.data.data.roles[0]);
          setFieldValue("showroomId", res.data.data.showroom_id);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("name")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.name && touched.name}
            helperText={errors.name}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("email")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email}
          />
        </Grid> */}
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("role")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="role"
              name="role"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.role}
              error={errors.role && touched.role}
            >
              <MenuItem value="admin">{locale("admin")}</MenuItem>
              <MenuItem value="showroom-admin">{locale("showroomAdmin")}</MenuItem>
              <MenuItem value="user">{locale("user")}</MenuItem>
            </Select>
            <FormHelperText>{errors.role}</FormHelperText>
          </FormControl>
        </Grid>
        {values.role === "showroom-admin" && (
          <Grid item xs={12}>
            <MDTypography component="p" variant="button" color="text" fontWeight="medium">
              {locale("showroom")}
            </MDTypography>
            <FormControl fullWidth size="large">
              <Select
                id="showroomId"
                name="showroomId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.showroomId}
                error={errors.showroomId && touched.showroomId}
              >
                {showrooms.map((showroom) => (
                  <MenuItem key={showroom.value} value={showroom.value}>
                    {showroom.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.showroomId}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("phone")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        {!id && (
          <>
            <Grid item xs={12}>
              <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                {locale("password")}
              </MDTypography>
              <TextField
                variant="outlined"
                type="password"
                fullWidth
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12}>
              <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                {locale("confirm_password")}
              </MDTypography>
              <TextField
                variant="outlined"
                type="password"
                fullWidth
                id="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirmPassword && touched.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default UserFormik;
