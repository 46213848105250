// Material Dashboard 2 React components
import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController, setSuccessSB, setErrorSB } from "context";
import useLocale from "context/useLocale";

function Snackbar() {
  const locale = useLocale();
  const [controller, dispatch] = useMaterialUIController();
  const { successSB, errorSB } = controller;

  const closeSuccessSB = () => setSuccessSB(dispatch, null);
  const closeErrorSB = () => setErrorSB(dispatch, null);

  const anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title={locale("brandName")}
      dateTime=""
      content={successSB || ""}
      open={!!successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      anchorOrigin={anchorOrigin}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={locale("brandName")}
      dateTime=""
      content={errorSB || ""}
      open={!!errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      anchorOrigin={anchorOrigin}
      bgWhite
    />
  );

  return (
    <>
      {renderSuccessSB}
      {renderErrorSB}
    </>
  );
}

export default Snackbar;
