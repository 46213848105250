import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { DropzoneArea } from "react-mui-dropzone";
import { Grid } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortableItem from "./sortableItem";

export default function Gallery({ onChange, value }) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (over === null) return;
    if (active.id !== over.id) {
      const oldIndex = value.findIndex((item) => item.id === active.id);
      const newIndex = value.findIndex((item) => item.id === over.id);

      onChange(arrayMove(value, oldIndex, newIndex));
    }
  };

  return (
    <>
      <DropzoneArea
        showPreviews={false}
        showPreviewsInDropzone={false}
        onDrop={(fs) => {
          const newValue = [
            ...value,
            ...fs.map((f, index) => ({
              id: Date.now() + index,
              file: f,
              preview: URL.createObjectURL(f),
            })),
          ];
          onChange(newValue);
        }}
        filesLimit={10}
        maxFileSize={11000000}
        clearOnUnmount
      />
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={value} strategy={rectSortingStrategy}>
          <Grid container spacing={2}>
            {value.map((image) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={image.id}>
                <SortableItem id={image.id}>
                  <div style={{ position: "relative" }}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        /**
                         * @type {{gallery: {id: number; file: string | File; preview?: string}[]}}
                         */
                        const newGallery = value.filter((i) => i.id !== image.id);
                        onChange([...newGallery]);
                      }}
                      style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <img
                      src={image.file instanceof File ? image.preview : image.file}
                      alt={`gallery-${image.id}`}
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                        marginTop: "40px",
                      }}
                    />
                  </div>
                </SortableItem>
              </Grid>
            ))}
          </Grid>
        </SortableContext>
      </DndContext>
    </>
  );
}

Gallery.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.array.isRequired,
};
