import MDTypography from "components/MDTypography";
import { fetchOptions as fetchCategoryOptions } from "services/VehicleCategories";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB } from "context";
import { fetchOptions as fetchBrandOptions } from "services/Brands";
import { useFormikContext } from "formik";
import { RadioGroup, Box, Typography, Grid, Radio, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import useLocale from "context/useLocale";

const StyledBox = styled(Box)(() => ({
  margin: "-15px",
  "& > div": {
    padding: "15px",
  },
}));

const CategoryBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "1em",
  width: "100%",
  height: "100%",
  aspectRatio: "1 / 1",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
}));

const CategoryImage = styled("img")({
  width: "60px",
  height: "55px",
  objectFit: "contain",
});

const CategoryTitle = styled(Typography)(({ selected }) => ({
  margin: 0,
  color: selected ? "blue" : "var(--black)",
  fontSize: "17px",
  textAlign: "center",
}));

const FirstLevelHeader = styled(Typography)(() => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  "&::after": {
    content: '"__________________________________________________________________________"',
    display: "inline-block",
    width: "100%",
    height: "auto",
    zIndex: -1,
    position: "absolute",
  },
}));

const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  margin: 0,
  width: "100%",
  "& .MuiFormControlLabel-label": {
    width: "100%",
  },
  "& .MuiRadio-root": {
    display: "none",
  },
  "& input:checked + .MuiFormControlLabel-label .MuiTypography-root": {
    color: "red",
  },
}));

function TrucksCategories({ setBrands, categoriesTree, setCategories1 }) {
  const { typeId } = useParams();
  const { handleBlur, values, errors, touched, setFieldValue, submitForm } = useFormikContext();
  const locale = useLocale();
  const [_, dispatch] = useMaterialUIController();

  const handleCategory1Change = (category0Id, category1Id) => {
    setFieldValue("category0Id", category0Id);
    fetchCategoryOptions({
      vehicle_type_id: typeId,
      parent_id: category0Id,
    }).then(
      (res) => {
        const options = res.data.data.data;
        options.sort((a, b) => a.label.localeCompare(b.label));
        setCategories1(options);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    setFieldValue("category1Id", category1Id);
    setFieldValue("brandId", "");
    setFieldValue("vehicleModelId", "");
    fetchBrandOptions({
      type_id: typeId,
      category_id: category1Id,
    }).then(
      (res) => {
        const brandsOptions = res.data.data.data;
        brandsOptions.sort((a, b) => a.label.localeCompare(b.label));
        setBrands(brandsOptions);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    submitForm();
  };

  return (
    <StyledBox>
      <Grid item xs={12}>
        <MDTypography component="h2" variant="button" color="text" fontWeight="medium">
          {locale("chooseVehicleCategory")}
        </MDTypography>
      </Grid>
      {categoriesTree.map((category) => (
        <Box key={category.id} sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box>
              <FirstLevelHeader variant="h4">{category.name}</FirstLevelHeader>
            </Box>
            <RadioGroup name="category1Id" value={values.category1Id} onBlur={handleBlur}>
              <Grid container spacing={2}>
                {category?.children?.map((child) => (
                  <Grid item xs={6} sm={3} lg={2} key={child.id}>
                    <StyledFormControlLabel
                      value={child.id.toString()}
                      control={
                        <Radio
                          onClick={(e) => {
                            handleCategory1Change(category.id.toString(), e.target.value);
                          }}
                        />
                      }
                      label={
                        <CategoryBox>
                          <CategoryImage src={child.icon} alt="" />
                          <Box className="single_cat__child_title">
                            <CategoryTitle selected={+values.category1Id === +child.id}>
                              {child.name}
                            </CategoryTitle>
                          </Box>
                        </CategoryBox>
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </Box>
        </Box>
      ))}
      {touched.category1Id && errors.category1Id && (
        <Typography color="error">{errors.category1Id}</Typography>
      )}
    </StyledBox>
  );
}

TrucksCategories.propTypes = {
  setBrands: PropTypes.func.isRequired,
  // eslint-disable-next-line
  categoriesTree: PropTypes.array.isRequired,
  setCategories1: PropTypes.func.isRequired,
};

export default TrucksCategories;
