// import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";

import { create, updateById } from "services/Users";
import { useNavigate, useParams } from "react-router-dom";
import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";
import { Formik } from "formik";
import * as Yup from "yup";
import useLocale from "context/useLocale";
import { rawPhoneNumber } from "util/general";
import UserFormik from "./form";

function UserForm() {
  const locale = useLocale();
  const { id } = useParams();
  const [, dispatch] = useMaterialUIController();
  const navigate = useNavigate();

  const submitForm = async (values) => {
    const form = new FormData();
    form.append("name", values.name);
    // form.append("email", values.email);
    form.append("phone_number", `+20${rawPhoneNumber(values.phoneNumber)}`);
    form.append("role", values.role);
    if (values.showroomId !== "") {
      form.append("showroom_id", values.showroomId);
    }
    if (!id) {
      form.append("password", values.password);
      form.append("password_confirmation", values.confirmPassword);
    }
    if (id) {
      updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name} user has been updated`);
          setTimeout(() => {
            navigate("/dashboard/user/");
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.name} user has been created`);
          setTimeout(() => {
            navigate("/dashboard/user/");
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {locale("usersForm")}
                </MDTypography>
              </MDBox>
              <MDBox p={4}>
                <Formik
                  initialValues={{
                    name: "",
                    email: "",
                    role: "",
                    phoneNumber: "",
                    showroomId: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  onSubmit={async (values) => {
                    await submitForm(values);
                  }}
                  validationSchema={Yup.object().shape({
                    name: Yup.string().required("Field is required"),
                    // email: Yup.string().email("Invalid Email").required("Field is required"),
                    role: Yup.string().required("Field is required"),
                    phoneNumber: Yup.string().required("Field is required"),
                    showroomId: Yup.string().notRequired(),
                    ...(id
                      ? {}
                      : {
                          password: Yup.string().required("Field is required").min(6),
                          confirmPassword: Yup.string()
                            .required("Field is required")
                            .oneOf([Yup.ref("password"), null], "Passwords must match"),
                        }),
                  })}
                >
                  <UserFormik />
                </Formik>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default UserForm;
