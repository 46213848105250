import { useMaterialUIController, setErrorSB, setSuccessSB } from "context";
import { create, updateById, fetchById, approveById } from "services/VehicleEntries";
import SelectRow from "components/Fields/SelectRow";
import CheckboxRow from "components/Fields/CheckboxRow";
import TextRow from "components/Fields/TextRow";
import { fetchAttributesFor } from "services/Attributes";
import { fetchOptions as fetchBrandOptions } from "services/Brands";
import { fetchOptions as fetchShowroomOptions } from "services/Showrooms";
import { fetchOptions as fetchBranchOptions } from "services/ShowroomBranches";
import { fetchById as fetchConditionById } from "services/VehicleConditions";
import { fetchById as fetchTypeById } from "services/VehicleTypes";
import { fetchOptions as fetchVariantOptions } from "services/VehicleVariants";
import {
  fetchOptions as fetchCategoryOptions,
  fetchAllMetaAsTree as fetchCategoriesAsTree,
} from "services/VehicleCategories";
import MDButton from "components/MDButton";
import {
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Icon,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchOptions as fetchModelOptions,
  fetchById as fetchVehicleModelById,
} from "services/VehicleModels";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import compressImage from "util/compress";
import useLocale from "context/useLocale";
import Gallery from "./gallery";
import TrucksCategories from "./trucksCategories";

function CustomFormLabel({ label, isRequired }) {
  return (
    <MDTypography component="p" variant="button" color="text" fontWeight="medium">
      {label}
      {isRequired && <span style={{ color: "red" }}>*</span>}
    </MDTypography>
  );
}

CustomFormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool.isRequired,
};

function genYearsOption() {
  const years = [];
  const currentYear = new Date().getFullYear() + 1;
  Array(currentYear - 1969)
    .fill(0)
    .forEach((element, index) => {
      years.push({
        label: (1970 + index).toString(),
        value: 1970 + index,
      });
    });
  years.sort((a, b) => (+a.label < +b.label ? 1 : -1));
  return years;
}

const yearsOptions = genYearsOption();

/**
 * @typedef {Object} GalleryItem
 * @property {number} id
 * @property {File | string} file
 * @property {string} relativePath
 */

// Wizard is a single Formik instance whose children are each page of the
// multi-step form. The form is submitted on each forward transition (can only
// progress with valid input), whereas a backwards step is allowed with
// incomplete data. A snapshot of form state is used as initialValues after each
// transition. Each page has an optional submit handler, and the top-level
// submit is called when the final page is submitted.
function Wizard({ children, initialValues, onSubmit }) {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children);
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (values, bag) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, bag);
    }
    if (isLastStep) {
      return onSubmit(values, bag);
    }
    bag.setTouched({});
    next(values);
    return undefined;
  };

  return (
    <Formik
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography component="p" variant="button" color="text" fontWeight="medium">
                Step {stepNumber + 1} of {totalSteps}
              </MDTypography>
            </Grid>
            {step}
          </Grid>
          <Grid display="flex" justifyContent="flex-start" mt={3}>
            {stepNumber > 0 && (
              <MDButton
                onClick={() => previous(formik.values)}
                type="button"
                variant="contained"
                color="info"
                startIcon={<Icon>send</Icon>}
                disabled={formik.isSubmitting}
              >
                Back
              </MDButton>
            )}
            <Grid display="flex" justifyContent="flex-end" sx={{ marginLeft: "auto", gap: "1em" }}>
              <ApproveEntry />
              <MDButton
                type="submit"
                variant="contained"
                color="info"
                endIcon={<Icon>send</Icon>}
                disabled={formik.isSubmitting}
              >
                {isLastStep ? "Submit" : "Next"}
              </MDButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

Wizard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

function WizardStep({ children }) {
  return children;
}

function ApproveEntry() {
  const { id, typeId, conditionId } = useParams();
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser } = controller;
  const navigate = useNavigate();
  const {
    values: { approved },
  } = useFormikContext();

  const approve = async () => {
    try {
      const res = await approveById(id);
      setSuccessSB(dispatch, `${res.data.data.title} entry has been approved`);
      setTimeout(() => {
        navigate(`/dashboard/vehicleEntry/${typeId}/${conditionId}`);
      }, 500);
    } catch (err) {
      setErrorSB(
        dispatch,
        err.response.data.message ? err.response.data.message : err.response.data.error.message
      );
    }
  };
  if (id && currentUser.roles.includes("admin") && !approved) {
    return (
      <MDButton
        type="button"
        variant="contained"
        color="success"
        endIcon={<Icon>check</Icon>}
        onClick={() => approve()}
      >
        Approve
      </MDButton>
    );
  }
  return null;
}

const newConditionFirstStepSchema = {
  // mainImage: Yup.mixed().required("Image is required"),
  brandId: Yup.string().required("Field is required"),
  vehicleModelId: Yup.string().required("Field is required"),
  variantId: Yup.string().required("Field is required"),
  price: Yup.number("Must be a number").required("Field is required"),
  description: Yup.string().notRequired("Field is required"),
  showroomId: Yup.string().notRequired(),
  showroomBranchId: Yup.string().required(),
  category0Id: Yup.string().notRequired(),
  category1Id: Yup.string().notRequired(),
};

const firstStepSchemaTemplate = {
  // mainImage: Yup.mixed().required("Image is required"),
  brandId: Yup.string().required("Field is required"),
  vehicleModelId: Yup.string().required("Field is required"),
  year: Yup.number("Must be a number")
    .min(1970)
    .max(new Date().getFullYear() + 1)
    .required("Field is required"),
  price: Yup.number("Must be a number").required("Field is required"),
  mileage: Yup.number("Must be a number").required("Field is required"),
  description: Yup.string().notRequired("Field is required"),
  showroomId: Yup.string().notRequired(),
  showroomBranchId: Yup.string().required(),
  category0Id: Yup.string().notRequired(),
  category1Id: Yup.string().notRequired(),
};

/**
 * category0/category1/brand/model are shared between first step in case of new or any other type so this functions act as sharing space for those common fields
 */
function SharedFirstStep({
  type,
  brands,
  setBrands,
  vehicleModels,
  setVehicleModels,
  categories0,
  categories1,
  setCategories1,
  onModelChange,
}) {
  const locale = useLocale();
  const { typeId } = useParams();
  const { setFieldValue, handleBlur, values, errors, touched } = useFormikContext();
  const [_, dispatch] = useMaterialUIController();

  const handleVehicleModelChange = (value) => {
    setFieldValue("vehicleModelId", value);
    if (onModelChange) {
      onModelChange(value);
    }
  };

  const handleCategory0Change = (value) => {
    setFieldValue("category0Id", value);
    fetchCategoryOptions({
      vehicle_type_id: typeId,
      parent_id: value,
    }).then(
      (res) => {
        const options = res.data.data.data;
        options.sort((a, b) => a.label.localeCompare(b.label));
        setCategories1(options);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  const handleCategory1Change = (value) => {
    setFieldValue("category1Id", value);
    setFieldValue("brandId", "");
    fetchBrandOptions({
      type_id: typeId,
      category_id: value,
    }).then(
      (res) => {
        const brandsOptions = res.data.data.data;
        brandsOptions.sort((a, b) => a.label.localeCompare(b.label));
        setBrands(brandsOptions);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  const handleBrandChange = (value) => {
    setFieldValue("brandId", value);
    setFieldValue("vehicleModelId", "");
    const modelFilters = {
      vehicle_type_id: typeId,
      brand_id: value,
    };
    if (values.category1Id) modelFilters.category_id = values.category1Id;
    fetchModelOptions(modelFilters).then(
      (res) => {
        const modelsOptions = res.data.data.data;
        modelsOptions.sort((a, b) => a.label.localeCompare(b.label));
        setVehicleModels(modelsOptions);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  return (
    <>
      {type.code === "truck" ? (
        <>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel label={locale("truck_classification")} isRequired />
            <FormControl fullWidth size="large">
              <Select
                id="category0Id"
                name="category0Id"
                onChange={(e) => {
                  handleCategory0Change(e.target.value);
                }}
                onBlur={handleBlur}
                value={values.category0Id}
                error={errors.category0Id && touched.category0Id}
              >
                {categories0.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.category0Id}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomFormLabel label={locale("truck_category")} isRequired />
            <FormControl fullWidth size="large">
              <Select
                id="category1Id"
                name="category1Id"
                onChange={(e) => {
                  handleCategory1Change(e.target.value);
                }}
                onBlur={handleBlur}
                value={values.category1Id}
                error={errors.category1Id && touched.category1Id}
              >
                {categories1.map((category) => (
                  <MenuItem key={category.value} value={category.value}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.category1Id}</FormHelperText>
            </FormControl>
          </Grid>
        </>
      ) : null}
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("brand")} isRequired />
        <FormControl fullWidth size="large">
          <Select
            id="brandId"
            name="brandId"
            onChange={(e) => {
              handleBrandChange(e.target.value);
            }}
            onBlur={handleBlur}
            value={values.brandId}
            error={errors.brandId && touched.brandId}
          >
            {brands.map((brand) => (
              <MenuItem key={brand.value} value={brand.value}>
                {brand.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.brandId}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("model")} isRequired />
        <FormControl fullWidth size="large">
          <Select
            id="vehicleModelId"
            name="vehicleModelId"
            onChange={(e) => {
              handleVehicleModelChange(e.target.value);
            }}
            onBlur={handleBlur}
            value={values.vehicleModelId}
            error={errors.vehicleModelId && touched.vehicleModelId}
          >
            {vehicleModels.map((vehicleModel) => (
              <MenuItem key={vehicleModel.value} value={vehicleModel.value}>
                {vehicleModel.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.vehicleModelId}</FormHelperText>
        </FormControl>
      </Grid>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
const sharedFirstStepPropTypes = {
  type: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  setBrands: PropTypes.func.isRequired,
  vehicleModels: PropTypes.array.isRequired,
  setVehicleModels: PropTypes.func.isRequired,
  categories0: PropTypes.array.isRequired,
  categories1: PropTypes.array.isRequired,
  setCategories1: PropTypes.func.isRequired,
  onModelChange: PropTypes.func,
};
/* eslint-enable react/forbid-prop-types */

SharedFirstStep.propTypes = sharedFirstStepPropTypes;
SharedFirstStep.defaultProps = { onModelChange: undefined };

function FirstStep({
  type,
  brands,
  setBrands,
  vehicleModels,
  setVehicleModels,
  initialShowrooms,
  initialBranches,
  categories0,
  categories1,
  setCategories1,
}) {
  const locale = useLocale();
  const { setFieldValue, handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const [controller, dispatch] = useMaterialUIController();
  const [showrooms] = useState(initialShowrooms);
  const [branches, setBranches] = useState(initialBranches);
  const { currentUser } = controller;

  // fetch branch options upon change of showroom id
  useEffect(() => {
    if (values.showroomId) {
      fetchBranchOptions(values.showroomId).then(
        (res) => {
          setBranches(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, [values.showroomId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDTypography component="h2" variant="button" color="text" fontWeight="medium">
          {locale("about_your_vehicle")}
        </MDTypography>
      </Grid>
      <Grid item xs={12}>
        <CustomFormLabel label={locale("gallery")} isRequired={false} />
        <Gallery value={values.gallery} onChange={(fs) => setFieldValue("gallery", fs)} />
      </Grid>
      <SharedFirstStep
        brands={brands}
        setBrands={setBrands}
        categories0={categories0}
        categories1={categories1}
        setCategories1={setCategories1}
        vehicleModels={vehicleModels}
        setVehicleModels={setVehicleModels}
        type={type}
      />
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("year")} isRequired />
        <FormControl fullWidth size="large">
          <Select
            variant="outlined"
            fullWidth
            id="year"
            name="year"
            value={values.year}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.year && touched.year}
          >
            {yearsOptions.map((year) => (
              <MenuItem key={year.value} value={year.value}>
                {year.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.year}</FormHelperText>
        </FormControl>
      </Grid>
      {
        // only show the mileage field in the first step if the type isn't truck
        type.code !== "truck" ? (
          <Grid item xs={12} sm={6}>
            <CustomFormLabel label={locale("mileage")} isRequired />
            <TextField
              variant="outlined"
              fullWidth
              id="mileage"
              value={values.mileage}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.mileage && touched.mileage}
              helperText={errors.mileage}
              type="number"
            />
          </Grid>
        ) : null
      }
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("price")} isRequired />
        <TextField
          variant="outlined"
          fullWidth
          id="price"
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.price && touched.price}
          helperText={errors.price}
          type="number"
        />
      </Grid>
      {/**
       * showing the options to select showroom and showroom branch depends on multiple cases
       * 1. when creating new entry from the dashboard it always should belong to showroom
       *  1. if the admin adding the entry is showroom admin don't show them the showroom option, only the branch option as the showroom option is useless
       *  2. if the admin is superadmin show both options as the superadmin could add entries to whatever showroom
       * 2. editing entry
       *  1. if the entry being edited belongs to showroom show the branch option (or showroom and branch options if the admin is superadmin)
       *  2. if the vehicle belongs to user don't show this showroom options to the admin
       */}
      {values.user ? null : (
        <>
          {!currentUser.showroom_id ? (
            <Grid item xs={12} sm={6}>
              <CustomFormLabel label={locale("showroom")} isRequired />
              <FormControl fullWidth size="large">
                <Select
                  id="showroomId"
                  name="showroomId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.showroomId}
                  error={errors.showroomId && touched.showroomId}
                >
                  {showrooms.map((showroom) => (
                    <MenuItem key={showroom.value} value={showroom.value}>
                      {showroom.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.showroomId}</FormHelperText>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12} sm={6}>
            <CustomFormLabel label={locale("branch")} isRequired />
            <FormControl fullWidth size="large">
              <Select
                id="showroomBranchId"
                name="showroomBranchId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.showroomBranchId}
                error={errors.showroomBranchId && touched.showroomBranchId}
              >
                {branches.map((branch) => (
                  <MenuItem key={branch.value} value={branch.value}>
                    {branch.label}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{errors.showroomBranchId}</FormHelperText>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <CustomFormLabel label={locale("description")} isRequired={false} />
        <TextField
          variant="outlined"
          fullWidth
          id="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.description && touched.description}
          helperText={errors.description}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}

/* eslint-disable react/forbid-prop-types */
FirstStep.propTypes = {
  ...sharedFirstStepPropTypes,
  type: PropTypes.object.isRequired,
  initialShowrooms: PropTypes.array.isRequired,
  initialBranches: PropTypes.array.isRequired,
};
/* eslint-enable react/forbid-prop-types */

function SecondStep({ type, setValidationSchema, attributes, setAttributes }) {
  const { typeId, conditionId } = useParams();
  const locale = useLocale();
  const { handleChange, handleBlur, values, errors, touched, setValues } = useFormikContext();
  const [loading, setLoading] = useState(true);
  const [dispatch] = useMaterialUIController();

  useEffect(() => {
    // please note this function will update intial values(make sure this function updates to the intial values don't remove other functions effect on the intial values)
    const fetchAttributesForVehicleTypeHandler = async () => {
      setLoading(true);
      try {
        const res = await fetchAttributesFor(
          typeId,
          conditionId,
          values.category1Id ?? undefined,
          true
        );
        const initialValuesUpdates = {};
        const validationSchemaCopy = {};
        const {
          data: { attributes: attributesData },
        } = res;
        attributesData.forEach((attribute) => {
          if (attribute.code === "mileage" && type.code !== "truck") return;
          if (attribute.input_type === "checkbox" && attribute.required) {
            initialValuesUpdates[attribute.code] = false;
            validationSchemaCopy[attribute.code] = Yup.boolean().required("Field is required");
          } else if (attribute.input_type === "checkbox") {
            initialValuesUpdates[attribute.code] = false;
            validationSchemaCopy[attribute.code] = Yup.boolean().notRequired();
          } else if (attribute.required) {
            validationSchemaCopy[attribute.code] = Yup.string().required("Field is required");
            initialValuesUpdates[attribute.code] = "";
          } else {
            initialValuesUpdates[attribute.code] = "";
            validationSchemaCopy[attribute.code] = Yup.string().notRequired();
          }
        });
        setValues((current) => {
          const newValues = {
            ...current,
          };
          // attributes values could already be loaded from loading existing vehicle we don't want to override those values
          Object.entries(initialValuesUpdates).forEach(([key, value]) => {
            if (!newValues[key]) {
              newValues[key] = value;
            }
          });
          return newValues;
        });
        setAttributes(attributesData);
        setValidationSchema(validationSchemaCopy);
        setLoading(false);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    fetchAttributesForVehicleTypeHandler();
  }, [values.category1Id, values.brandId]);

  if (loading) {
    return <p>Loading</p>;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDTypography component="h2" variant="button" color="text" fontWeight="medium">
          {locale("customAttributes")}
        </MDTypography>
      </Grid>
      {attributes.map((attribute) => {
        if (attribute.input_type === "text") {
          if (attribute.code === "mileage" && type.code !== "truck") return null;
          return (
            <TextRow
              key={attribute.id}
              title={attribute.title}
              code={attribute.code}
              value={values[attribute.code]}
              error={errors[attribute.code]}
              touched={touched[attribute.code]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              isRequired={
                attribute.required === 1 ||
                attribute.required === "1" ||
                attribute.required === true
              }
            />
          );
        }
        if (attribute.input_type === "select" || attribute.input_type === "multiselect") {
          return (
            <SelectRow
              key={attribute.id}
              title={attribute.title}
              code={attribute.code}
              value={values[attribute.code]}
              error={errors[attribute.code]}
              touched={touched[attribute.code]}
              handleChange={handleChange}
              handleBlur={handleBlur}
              options={attribute.options}
              multiselect={attribute.input_type !== "select"}
              isRequired={
                attribute.required === 1 ||
                attribute.required === "1" ||
                attribute.required === true
              }
            />
          );
        }
        return "";
      })}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {attributes.map((attribute) => {
            if (attribute.input_type === "checkbox") {
              return (
                <CheckboxRow
                  key={attribute.id}
                  title={attribute.title}
                  code={attribute.code}
                  value={values[attribute.code]}
                  error={errors[attribute.code]}
                  touched={touched[attribute.code]}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  isRequired={
                    attribute.required === 1 ||
                    attribute.required === "1" ||
                    attribute.required === true
                  }
                />
              );
            }
            return "";
          })}
        </Grid>
      </Grid>
    </Grid>
  );
}

SecondStep.propTypes = {
  // eslint-disable-next-line
  type: PropTypes.object.isRequired,
  setValidationSchema: PropTypes.func.isRequired,
  // eslint-disable-next-line
  attributes: PropTypes.array.isRequired,
  setAttributes: PropTypes.func.isRequired,
};

function NewConditionFirstStep({
  type,
  brands,
  setBrands,
  vehicleModels,
  setVehicleModels,
  initialShowrooms,
  initialBranches,
  initialVehicleVariants,
  categories0,
  categories1,
  setCategories1,
}) {
  const locale = useLocale();
  const { setFieldValue, handleChange, handleBlur, values, errors, touched } = useFormikContext();
  const [controller, dispatch] = useMaterialUIController();
  const [showrooms] = useState(initialShowrooms);
  const [branches, setBranches] = useState(initialBranches);
  const [vehicleVariants, setVehicleVariants] = useState(initialVehicleVariants);
  const { currentUser } = controller;

  const fetchVariants = (vehicleModelId, variantId = null) => {
    fetchVariantOptions({
      vehicle_model_id: vehicleModelId,
    }).then(
      (res) => {
        setVehicleVariants(res.data.data.data);
        if (variantId) {
          setFieldValue("vehicleVariantId", variantId);
        }
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
  };

  const handleVehicleModelChange = (value) => {
    setFieldValue("vehicleVariantId", "");
    fetchVariants(value);
  };

  // fetch branch options upon change of showroom id
  useEffect(() => {
    if (values.showroomId) {
      fetchBranchOptions(values.showroomId).then(
        (res) => {
          setBranches(res.data.data.data);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, [values.showroomId]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDTypography component="h2" variant="button" color="text" fontWeight="medium">
          {locale("about_your_vehicle")}
        </MDTypography>
      </Grid>
      <Grid item xs={12}>
        <CustomFormLabel label={locale("gallery")} isRequired={false} />
        <Gallery value={values.gallery} onChange={(fs) => setFieldValue("gallery", fs)} />
      </Grid>
      <SharedFirstStep
        brands={brands}
        setBrands={setBrands}
        categories0={categories0}
        categories1={categories1}
        setCategories1={setCategories1}
        vehicleModels={vehicleModels}
        setVehicleModels={setVehicleModels}
        type={type}
        onModelChange={handleVehicleModelChange}
      />
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("class")} isRequired />
        <FormControl fullWidth size="large">
          <Select
            id="variantId"
            name="variantId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.variantId}
            error={errors.variantId && touched.variantId}
          >
            {vehicleVariants.map((vehicleVariant) => (
              <MenuItem key={vehicleVariant.value} value={vehicleVariant.value}>
                {vehicleVariant.label} - {vehicleVariant.year}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.vehicleModelId}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("price")} isRequired />
        <TextField
          variant="outlined"
          fullWidth
          id="price"
          value={values.price}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.price && touched.price}
          helperText={errors.price}
        />
      </Grid>
      {!currentUser.showroom_id && values.showroomId && (
        <Grid item xs={12}>
          <CustomFormLabel label={locale("showroom")} isRequired />
          <FormControl fullWidth size="large">
            <Select
              id="showroomId"
              name="showroomId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.showroomId}
              error={errors.showroomId && touched.showroomId}
            >
              {showrooms.map((showroom) => (
                <MenuItem key={showroom.value} value={showroom.value}>
                  {showroom.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.showroomId}</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <CustomFormLabel label={locale("branch")} isRequired />
        <FormControl fullWidth size="large">
          <Select
            id="showroomBranchId"
            name="showroomBranchId"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.showroomBranchId}
            error={errors.showroomBranchId && touched.showroomBranchId}
          >
            {branches.map((branch) => (
              <MenuItem key={branch.value} value={branch.value}>
                {branch.label}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{errors.showroomBranchId}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <CustomFormLabel label={locale("description")} isRequired={false} />
        <TextField
          variant="outlined"
          fullWidth
          id="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.description && touched.description}
          helperText={errors.description}
          multiline
          rows={4}
        />
      </Grid>
    </Grid>
  );
}

/* eslint-disable react/forbid-prop-types */
NewConditionFirstStep.propTypes = {
  ...sharedFirstStepPropTypes,
  type: PropTypes.object.isRequired,
  initialShowrooms: PropTypes.array.isRequired,
  initialBranches: PropTypes.array.isRequired,
  initialVehicleVariants: PropTypes.array.isRequired,
};

/* eslint-enable react/forbid-prop-types */
export default function VehicleEntryMultipleStepFormik() {
  const { id, typeId, conditionId } = useParams();
  const isEditingVehicle = !!id;
  const [controller, dispatch] = useMaterialUIController();
  const { currentUser } = controller;
  const navigate = useNavigate();
  const [firstStepSchema, setFirstStepSchema] = useState(firstStepSchemaTemplate);
  const [secondStepValidationSchema, setSecondStepValidationSchema] = useState({});
  // hold the intial values for the form
  const [formInitialValues, setFormInitialValues] = useState({
    title: "",
    description: "",
    year: "",
    // mainImage: "",
    brandId: "",
    vehicleModelId: "",
    price: "",
    showroomId: "",
    showroomBranchId: "",
    email: "",
    phone: "",
    mileage: "",
    variantId: "",
    gallery: [],
    /**
     * category level zero id
     */
    category0Id: "",
    /**
     * category level zero id
     */
    category1Id: "",
  });
  const [brands, setBrands] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [initialBranches, setInitialBranches] = useState([]);
  const [initialShowrooms, setInitialShowrooms] = useState([]);
  const [initialVehicleVariants, setInitialVehicleVariants] = useState([]);
  const [categories0, setCategories0] = useState([]);
  const [categories1, setCategories1] = useState([]);
  const [categoriesTree, setCategoriesTree] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [type, setType] = useState(null);
  /**
   * @typedef {Object} Condition
   * @property {number} id
   * @property {Object} title
   * @property {string} title.ar
   * @property {string} title.en
   * @property {string} code
   * @property {string} image
   * @property {string} created_at
   * @property {string} updated_at
   */

  const [condition, setCondition] = useState(/** @type {Condition} */ (null));

  useEffect(() => {
    const fetchCategories0Handler = async () => {
      try {
        const res = await fetchCategoryOptions({
          vehicle_type_id: typeId,
          parent_id: null,
        });
        setCategories0(res.data.data.data);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    const fetchCategories1Handler = async (vehicleTypeId, parentId) => {
      try {
        const res = await fetchCategoryOptions({
          vehicle_type_id: vehicleTypeId,
          parent_id: parentId,
        });
        setCategories1(res.data.data.data);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    const fetchCategoriesTreeHandler = async () => {
      try {
        const res = await fetchCategoriesAsTree(typeId);
        setCategoriesTree(res.data.truck_categories);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    const fetchTypeHandler = async () => {
      try {
        const res = await fetchTypeById(typeId);
        const types = res.data.data;
        setType(types);
        return types;
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
      return undefined;
    };
    const fetchConditionHandler = async () => {
      try {
        const res = await fetchConditionById(conditionId);
        const conditions = res.data.data;
        setCondition(conditions);
        return conditions;
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
      return undefined;
    };
    const fetchBrandOptionsHandler = async (categoryId = undefined) => {
      try {
        const filter = {
          type_id: typeId,
        };
        if (categoryId) filter.category_id = categoryId;
        const res = await fetchBrandOptions(filter);
        const brandsOptions = res.data.data.data;
        brandsOptions.sort((a, b) => a.label.localeCompare(b.label));
        setBrands(brandsOptions);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    const fetchShowroomOptionsHandler = async () => {
      if (!currentUser.showroom_id) {
        try {
          const res = await fetchShowroomOptions();
          setInitialShowrooms(res.data.data.data);
        } catch (err) {
          setErrorSB(dispatch, `${err.response.data.error.message}`);
        }
      }
    };
    const fetchModelOptionsHandler = async (vehicleTypeId, brandId) => {
      try {
        const res = await fetchModelOptions({
          vehicle_type_id: vehicleTypeId,
          brand_id: brandId,
        });
        const modelsOptions = res.data.data.data;
        modelsOptions.sort((a, b) => a.label.localeCompare(b.label));
        setVehicleModels(modelsOptions);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };
    // fetch initial brances if the user is showroom admin
    const fetchBranchOptionsHandler = async () => {
      if (currentUser.showroom_id) {
        try {
          const res = await fetchBranchOptions(currentUser.showroom_id);
          setInitialBranches(res.data.data.data);
        } catch (err) {
          setErrorSB(dispatch, `${err.response.data.error.message}`);
        }
      }
    };

    const fetchVariantsHandler = async (vehicleModelId) => {
      try {
        const res = await fetchVariantOptions({
          vehicle_model_id: vehicleModelId,
        });
        setInitialVehicleVariants(res.data.data.data);
      } catch (err) {
        setErrorSB(dispatch, `${err.response.data.error.message}`);
      }
    };

    // please note this function will update intial values(make sure this function updates to the intial values don't remove other functions effect on the intial values)
    const fetchVehicleIntialValues = async (typeObj) => {
      const initialValuesUpdates = {};
      const firstStepSchemaValidationUpdates = {};
      if (id) {
        try {
          const res = await fetchById(id);
          initialValuesUpdates.title = res.data.data.title;
          initialValuesUpdates.mileage = res.data.data.mileage;
          initialValuesUpdates.description = res.data.data.description;
          initialValuesUpdates.vehicleModelId = res.data.data.vehicle_model.id;
          if (typeObj.code === "truck") {
            const {
              data: { data: modelData },
            } = await fetchVehicleModelById(res.data.data.vehicle_model.id);
            await fetchCategories1Handler(typeId, modelData.vehicle_category.parent.id);
            initialValuesUpdates.category0Id = modelData.vehicle_category.parent.id;
            initialValuesUpdates.category1Id = modelData.vehicle_category.id;
            await fetchBrandOptionsHandler(modelData.vehicle_category.id);
          }
          initialValuesUpdates.brandId = res.data.data.vehicle_model.brand.id;
          initialValuesUpdates.price = res.data.data.price;
          initialValuesUpdates.year = res.data.data.year;
          /// the vehicle could be from user or showroom, and i think most of the components consuming this data will need to know this fact so they can do any kind
          /// of conditional rendering
          // so the components should decide based on the following condition if the vehicle is from user or showroom(**the components should fallback to the case of showroom in case of adding new entry tho**)
          // if(vehicle.showroomId) // showroom vehicle
          // if (vehicle.user) // user vehicle
          initialValuesUpdates.showroomId = res.data.data.seller?.showroom?.id;
          initialValuesUpdates.showroomBranchId = res.data.data.seller?.branch?.id;
          initialValuesUpdates.user = res.data.data.seller?.user;
          firstStepSchemaValidationUpdates.showroomBranchId = Yup.string().notRequired();

          initialValuesUpdates.gallery = [
            {
              id: -1,
              file: `${process.env.REACT_APP_BACKEND_URL}/storage/${res.data.data.main_image}`,
              relativePath: res.data.data.main_image,
            },
          ];
          res.data.data.images.forEach((image) => {
            initialValuesUpdates.gallery.push({
              id: image.id,
              file: `${process.env.REACT_APP_BACKEND_URL}/storage/${image.image}`,
              relativePath: image.image,
            });
          });
          initialValuesUpdates.variantId = res.data.data.vehicle_variant_id;
          res.data.data.attribute_values.forEach((attributeValue) => {
            if (attributeValue.attribute.input_type === "checkbox") {
              initialValuesUpdates[attributeValue.attribute.code] =
                attributeValue.value === "1" ||
                attributeValue.value === true ||
                attributeValue.value === 1;
            } else {
              initialValuesUpdates[attributeValue.attribute.code] = attributeValue.value ?? "";
            }
          });
          initialValuesUpdates.approved = res.data.data.approved;

          await Promise.all([
            fetchModelOptionsHandler(typeId, res.data.data.vehicle_model.brand.id),
            res.data.data.condition.code === "new"
              ? fetchVariantsHandler(res.data.data.vehicle_model.id)
              : undefined,
          ]);
        } catch (err) {
          setErrorSB(dispatch, `${err.response.data.error.message}`);
        }
      } else if (currentUser.showroom_id) {
        initialValuesUpdates.showroomId = currentUser.showroom_id;
      }
      setFormInitialValues((prev) => ({
        ...prev,
        ...initialValuesUpdates,
      }));
      setFirstStepSchema((prev) => ({
        ...prev,
        ...firstStepSchemaValidationUpdates,
      }));
    };

    const loadData = async () => {
      setLoading(true);
      // eslint-disable-next-line no-shadow
      const [type] = await Promise.all([fetchTypeHandler(), fetchConditionHandler()]);

      await Promise.all([
        type?.code === "truck" ? fetchCategories0Handler() : undefined,
        type?.code === "truck" ? fetchCategoriesTreeHandler() : undefined,
        type?.code !== "truck" ? fetchBrandOptionsHandler() : undefined,
        fetchShowroomOptionsHandler(),
        fetchBranchOptionsHandler(),
      ]);
      await fetchVehicleIntialValues(type);

      const firstStepSchemaValidationUpdates = {};
      // mileage field should be handled by second step(attributes step) in case of truck so the mileage required validation should be removed
      if (type.code === "truck") firstStepSchemaValidationUpdates.mileage = undefined;
      setFirstStepSchema((prev) => ({
        ...prev,
        ...firstStepSchemaValidationUpdates,
      }));
      setLoading(false);
    };
    loadData();
  }, []);

  const submitForm = async (values) => {
    const isConditionNew = condition.code === "new";
    const form = new FormData();
    form.append("title", values.title);
    form.append("description", values.description ?? "");
    form.append("vehicle_model_id", values.vehicleModelId);
    form.append("price", values.price);
    if (values.showroomId && values.showroomBranchId) {
      form.append("showroom_id", values.showroomId);
      form.append("showroom_branch_id", values.showroomBranchId);
    }
    form.append("vehicle_type_id", typeId);
    form.append("vehicle_condition_id", conditionId);

    /**
     * @type {{gallery: GalleryItem[]}}
     */
    const { gallery } = values;
    /**
     * @type {GalleryItem | null}
     */
    const mainImage = gallery.length > 0 ? gallery[0] : null;

    await Promise.all(
      gallery.slice(1).map(async (image) => {
        if (image.file instanceof File) {
          form.append("gallery[]", await compressImage(image.file), image.file.name);
        } else {
          form.append("gallery[]", image.relativePath);
        }
      })
    );

    if (mainImage && mainImage.file instanceof File) {
      form.append("main_image", await compressImage(mainImage.file), mainImage.file.name);
    } else if (mainImage && typeof mainImage.file === "string") {
      form.append("main_image", mainImage.relativePath);
    }

    // set the data the differes between condition = "new" and other conditions(fullfil the discriminated union)
    if (isConditionNew) {
      form.append("is_condition_new", 1);
      form.append("vehicle_variant_id", values.variantId);
    } else {
      form.append("year", values.year);
      form.append("mileage", values.mileage ?? "");

      attributes.forEach((attribute, index) => {
        form.append(`attribute_values[${index}][id]`, attribute.id);
        if (attribute.input_type === "checkbox") {
          form.append(`attribute_values[${index}][value]`, values[attribute.code] ? 1 : 0);
        } else {
          form.append(`attribute_values[${index}][value]`, values[attribute.code]);
        }
      });
    }

    if (id) {
      await updateById(id, form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title} entry has been updated`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleEntry/${typeId}/${conditionId}/`);
          }, 500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    } else {
      await create(form).then(
        (res) => {
          setSuccessSB(dispatch, `${res.data.data.title} entry has been created`);
          setTimeout(() => {
            navigate(`/dashboard/vehicleEntry/${typeId}/${conditionId}/`);
          }, 1500);
        },
        (err) =>
          setErrorSB(
            dispatch,
            err.response.data.message ? err.response.data.message : err.response.data.error.message
          )
      );
    }
  };

  if (loading) {
    return <p>Loading</p>;
  }
  // if we passed loading and still don't have condition that means some error happened while loading conditions and we cannot really operate without knowing the condition as there are different forms to show based on vehilce condition
  if (!condition) {
    return <p>Error Has happened please try again later</p>;
  }

  return condition.code === "new" ? (
    <Wizard initialValues={formInitialValues} onSubmit={submitForm}>
      {type.code === "truck" && !isEditingVehicle ? (
        <WizardStep key="trucksCatrgogi">
          <TrucksCategories
            setBrands={setBrands}
            categoriesTree={categoriesTree}
            setCategories1={setCategories1}
          />
        </WizardStep>
      ) : null}
      <WizardStep
        key="newconditionFirstStep"
        validationSchema={Yup.object().shape(newConditionFirstStepSchema)}
      >
        <NewConditionFirstStep
          type={type}
          condition={condition}
          initialBranches={initialBranches}
          brands={brands}
          setBrands={setBrands}
          initialShowrooms={initialShowrooms}
          vehicleModels={vehicleModels}
          setVehicleModels={setVehicleModels}
          initialVehicleVariants={initialVehicleVariants}
          categories0={categories0}
          categories1={categories1}
          setCategories1={setCategories1}
        />
      </WizardStep>
    </Wizard>
  ) : (
    <Wizard initialValues={formInitialValues} onSubmit={submitForm}>
      {type.code === "truck" && !isEditingVehicle ? (
        <WizardStep key="trucksCatrgogi">
          <TrucksCategories
            setBrands={setBrands}
            categoriesTree={categoriesTree}
            setCategories1={setCategories1}
          />
        </WizardStep>
      ) : null}
      <WizardStep key="firststep" validationSchema={Yup.object().shape(firstStepSchema)}>
        <FirstStep
          type={type}
          condition={condition}
          initialBranches={initialBranches}
          brands={brands}
          setBrands={setBrands}
          initialShowrooms={initialShowrooms}
          vehicleModels={vehicleModels}
          setVehicleModels={setVehicleModels}
          categories0={categories0}
          categories1={categories1}
          setCategories1={setCategories1}
        />
      </WizardStep>
      <WizardStep
        key="secondstep"
        validationSchema={Yup.object().shape(secondStepValidationSchema)}
      >
        <SecondStep
          type={type}
          condition={condition}
          attributes={attributes}
          setAttributes={setAttributes}
          setValidationSchema={setSecondStepValidationSchema}
        />
      </WizardStep>
    </Wizard>
  );
}
