/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
// @mui material components
import { Dialog, DialogActions, DialogTitle, Icon, IconButton } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

// Material Dashboard 2 React components

import MDTypography from "components/MDTypography";
import { useMaterialUIController, setSuccessSB, setErrorSB } from "context";
import useLocale from "context/useLocale";
import { useState, useEffect } from "react";
import Moment from "react-moment";
import { useParams } from "react-router-dom";

import { fetchAll, deleteById } from "services/VehicleEntries";

export default function data() {
  const locale = useLocale();
  const [, dispatch] = useMaterialUIController();
  const [rows, setRows] = useState([]);
  const [searchParams, setSearchParams] = useState({ price: "", approved: "" });
  const [sortBy, setSortBy] = useState("updated_at");
  const [sortOrder, setSortOrder] = useState("desc");
  const [paginationMeta, setPaginationMeta] = useState({});
  const { typeId, conditionId } = useParams();
  const [open, setOpen] = useState([]);

  const handleClickOpen = (id) => {
    const openCopy = [...open];
    openCopy[id] = true;
    setOpen(openCopy);
  };

  const handleClose = (id) => {
    const openCopy = [...open];
    openCopy[id] = false;
    setOpen(openCopy);
  };

  const handleSearchChange = (key) => (e) => {
    setSearchParams((prevParams) => ({
      ...prevParams,
      [key]: e.target.value,
    }));
  };

  const handleSortChange = (sortColumn) => {
    let newSortOrder = "asc";
    if (sortBy === sortColumn && sortOrder === "asc") {
      newSortOrder = "desc";
    }
    setSortBy(sortColumn);
    setSortOrder(newSortOrder);
  };

  const loadData = (page) => {
    fetchAll(
      {
        "model.type": typeId,
        vehicle_condition_id: conditionId,
      },
      page,
      searchParams,
      sortBy,
      sortOrder
    ).then(
      (res) => {
        setRows(res.data.data.data);
        setPaginationMeta(res.data.data.meta.pagination);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)(err)
    );
  };

  const handleDelete = (id) => {
    deleteById(id).then(
      (res) => {
        loadData(1);
        setSuccessSB(dispatch, `${res.data.data.title} vehicle entry has been removed`);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    handleClose(id);
  };

  useEffect(() => {
    loadData(1);
  }, [typeId, conditionId, searchParams, sortBy, sortOrder]);

  const renderRows = rows.map((row) => ({
    id: row.id,
    title: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {row.title}
      </MDTypography>
    ),
    mainImage: (
      <MDAvatar
        src={`${process.env.REACT_APP_BACKEND_URL}/storage/${row.main_image}`}
        alt={row.title}
        size="lg"
        variant="square"
      />
    ),
    price: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {row.price}
      </MDTypography>
    ),
    approved: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        {row.approved}
      </MDTypography>
    ),
    created_at: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        <Moment format="DD-MMM-YYYY, hh:mm A">{row.created_at}</Moment>
      </MDTypography>
    ),
    updated_at: (
      <MDTypography component="p" variant="button" color="text" fontWeight="medium">
        <Moment format="DD-MMM-YYYY, hh:mm A">{row.updated_at}</Moment>
      </MDTypography>
    ),
    action: (
      <>
        <IconButton aria-label="edit" color="blue" href={`edit/${row.id}`}>
          <Icon fontSize="sm">edit</Icon>
        </IconButton>

        <IconButton aria-label="delete" color="primary" onClick={() => handleClickOpen(row.id)}>
          <Icon fontSize="sm">delete</Icon>
        </IconButton>
        <Dialog
          open={typeof open[row.id] !== "undefined" && open[row.id]}
          onClose={() => handleClose(row.id)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure you want to delete (${row.title}) ?`}
          </DialogTitle>
          <DialogActions>
            <MDButton variant="contained" onClick={() => handleClose(row.id)}>
              {locale("cancel")}
            </MDButton>
            <MDButton variant="contained" color="error" onClick={() => handleDelete(row.id)}>
              {locale("confirm")}
            </MDButton>
          </DialogActions>
        </Dialog>
      </>
    ),
  }));

  return {
    columns: [
      { Header: locale("id"), accessor: "id" },
      { Header: locale("title"), accessor: "title" },
      { Header: locale("mainImage"), accessor: "mainImage" },
      { Header: locale("price"), accessor: "price", searchable: "true" },
      { Header: locale("approved"), accessor: "approved", searchable: "true" },
      { Header: locale("createdAt"), accessor: "created_at" },
      { Header: locale("updatedAt"), accessor: "updated_at" },
      { Header: locale("actions"), accessor: "action", align: "left" },
    ],
    rows: renderRows,
    paginationMeta,
    loadData,
    handleSearchChange,
    handleSortChange,
  };
}
