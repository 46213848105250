import { Grid, Icon, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/Showrooms";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function ShowroomFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState();
  const [coverPreview, setCoverPreview] = useState();
  const [, dispatch] = useMaterialUIController();

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.image, setImagePreview);
  }, [values.image]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.cover, setCoverPreview);
  }, [values.cover]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  if (id) {
    useEffect(() => {
      fetchById(id).then(
        (res) => {
          // eslint-disable-next-line prefer-destructuring
          const data = res.data.data;
          setFieldValue("nameEn", data.name.en);
          setFieldValue("nameAr", data.name.ar);
          // if no description is available the backend returns empty array, otherwise it returns the description as object containing en and ar
          if (data.description !== null && !Array.isArray(data.description)) {
            setFieldValue("descriptionEn", data.description.en ?? "");
            setFieldValue("descriptionAr", data.description.ar ?? "");
          } else {
            setFieldValue("descriptionEn", "");
            setFieldValue("descriptionAr", "");
          }
          setFieldValue("image", data.image);
          setFieldValue("cover", data.cover);
          setFieldValue("website", data.website ?? "");
          setFieldValue("youtube", data.youtube ?? "");
          setFieldValue("instagram", data.instagram ?? "");
          setFieldValue("facebook", data.facebook ?? "");
          setFieldValue("phoneNumber", data.phone_number);
          setFieldValue("email", data.email ?? "");
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }, []);
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameEn"
            value={values.nameEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameEn && touched.nameEn}
            helperText={errors.nameEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("nameAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="nameAr"
            value={values.nameAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.nameAr && touched.nameAr}
            helperText={errors.nameAr}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("descriptionEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="descriptionEn"
            value={values.descriptionEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.descriptionEn && touched.descriptionEn}
            helperText={errors.descriptionEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("descriptionAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            id="descriptionAr"
            value={values.descriptionAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.descriptionAr && touched.descriptionAr}
            helperText={errors.descriptionAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("image")}
          </MDTypography>
          {imagePreview && <MDAvatar src={imagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="image"
            name="image"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "image");
            }}
            onBlur={handleBlur}
            error={errors.image && touched.image}
            helperText={errors.image}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("cover")}
          </MDTypography>
          {coverPreview && <MDAvatar src={coverPreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="cover"
            name="cover"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "cover");
            }}
            onBlur={handleBlur}
            error={errors.cover && touched.cover}
            helperText={errors.cover}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("phone")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="phoneNumber"
            value={values.phoneNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.phoneNumber && touched.phoneNumber}
            helperText={errors.phoneNumber}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("email")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            helperText={errors.email}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("website")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="website"
            value={values.website}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.website && touched.website}
            helperText={errors.website}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("youtube")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="youtube"
            value={values.youtube}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.youtube && touched.youtube}
            helperText={errors.youtube}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("instagram")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="instagram"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.instagram && touched.instagram}
            helperText={errors.instagram}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("facebook")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.facebook && touched.facebook}
            helperText={errors.facebook}
          />
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default ShowroomFormik;
