import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Icon,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchById } from "services/VehicleVariants";
import { fetchOptions as fetchModelOptions } from "services/VehicleModels";
import { useMaterialUIController, setErrorSB } from "context";
import MDTypography from "components/MDTypography";
import useLocale from "context/useLocale";

function VehicleVariantFormik() {
  const locale = useLocale();
  const {
    setFieldValue,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isSubmitting,
  } = useFormikContext();
  const { id, typeId } = useParams();
  const [mainImagePreview, setMainImagePreview] = useState();
  const [vehicleModels, setVehicleModels] = useState([]);
  const [, dispatch] = useMaterialUIController();

  const updatePreview = (img, setImg) => {
    if (!img) {
      setImg(undefined);
      return;
    }

    if (img instanceof File) {
      const objectUrl = URL.createObjectURL(img);
      setImg(objectUrl);

      // free memory when ever this component is unmounted
      // eslint-disable-next-line consistent-return
      return () => URL.revokeObjectURL(objectUrl);
    }

    setImg(`${process.env.REACT_APP_BACKEND_URL}/storage/${img}`);
  };

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    updatePreview(values.mainImage, setMainImagePreview);
  }, [values.mainImage]);

  const onSelectFile = (e, imageVariable) => {
    if (!e.target.files || e.target.files.length === 0) {
      setFieldValue(imageVariable, undefined);
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setFieldValue(imageVariable, e.target.files[0]);
  };

  useEffect(async () => {
    fetchModelOptions({
      vehicle_type_id: typeId,
    }).then(
      (res) => {
        setVehicleModels(res.data.data.data);
      },
      (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
    );
    if (id) {
      await fetchById(id).then(
        (res) => {
          setFieldValue("titleEn", res.data.data.title.en);
          setFieldValue("titleAr", res.data.data.title.ar);
          setFieldValue("mainImage", res.data.data.main_image);
          setFieldValue("vehicleModelId", res.data.data.vehicle_model.id);
          setFieldValue("year", res.data.data.year);
          setFieldValue("showAsNew", res.data.data.show_as_new);
        },
        (err) => setErrorSB(dispatch, `${err.response.data.error.message}`)
      );
    }
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleEn")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleEn"
            value={values.titleEn}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleEn && touched.titleEn}
            helperText={errors.titleEn}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("titleAr")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="titleAr"
            value={values.titleAr}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.titleAr && touched.titleAr}
            helperText={errors.titleAr}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("model")}
          </MDTypography>
          <FormControl fullWidth size="large">
            <Select
              id="vehicleModelId"
              name="vehicleModelId"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vehicleModelId}
              error={errors.vehicleModelId && touched.vehicleModelId}
            >
              {vehicleModels.map((vehicleModel) => (
                <MenuItem key={vehicleModel.value} value={vehicleModel.value}>
                  {vehicleModel.label.en}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.vehicleModelId}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("mainImage")}
          </MDTypography>
          {mainImagePreview && <MDAvatar src={mainImagePreview} size="xxl" variant="square" />}
          <TextField
            variant="outlined"
            fullWidth
            id="mainImage"
            name="mainImage"
            type="file"
            onChange={(e) => {
              onSelectFile(e, "mainImage");
            }}
            onBlur={handleBlur}
            error={errors.mainImage && touched.mainImage}
            helperText={errors.mainImage}
          />
        </Grid>
        <Grid item xs={12}>
          <MDTypography component="p" variant="button" color="text" fontWeight="medium">
            {locale("year")}
          </MDTypography>
          <TextField
            variant="outlined"
            fullWidth
            id="year"
            value={values.year}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.year && touched.year}
            helperText={errors.year}
          />
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  value={values.showAsNew}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.showAsNew && touched.showAsNew}
                />
              }
              label={locale("showAsNew")}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid display="flex" justifyContent="flex-end" mt={3}>
        <MDButton
          type="submit"
          variant="contained"
          color="info"
          endIcon={<Icon>send</Icon>}
          disabled={isSubmitting}
        >
          {locale("submit")}
        </MDButton>
      </Grid>
    </form>
  );
}

export default VehicleVariantFormik;
