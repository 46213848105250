import { authAxios } from "services";

export const fetchAll = (page) => authAxios().get(`/regions?page=${page}`);

export const fetchOptions = (cityId = "") => authAxios().get(`/regions/options?city_id=${cityId}`);

export const create = (data) => authAxios().post(`/regions`, data);

export const fetchById = (id) => authAxios().get(`/regions/${id}`);

export const deleteById = (id) => authAxios().delete(`/regions/${id}`);

export const updateById = (id, data) => authAxios().post(`/regions/${id}?_method=PUT`, data);
