import { authAxios } from "services";

export const fetchAll = (filters, page, searchParams = {}) => {
  const adjustedFilters = Object.keys(filters).map((filterIndex) => [
    `filters[${filterIndex}]`,
    filters[filterIndex],
  ]);
  const urlParams = new URLSearchParams(adjustedFilters);
  urlParams.append("page", page);

  Object.keys(searchParams).forEach((key) => {
    if (searchParams[key]) {
      urlParams.append(key, searchParams[key]);
    }
  });

  return authAxios().get(`/vehicleModels?${urlParams.toString()}`);
};

export const fetchOptions = (filters) => {
  const adjustedFilters = Object.keys(filters).map((filterIndex) => [
    `filters[${filterIndex}]`,
    filters[filterIndex],
  ]);
  const urlParams = new URLSearchParams(adjustedFilters).toString();
  return authAxios().get(`/vehicleModels/options?${urlParams}`);
};

export const create = (data) => authAxios().post(`/vehicleModels`, data);

export const fetchById = (id) => authAxios().get(`/vehicleModels/${id}`);

export const deleteById = (id) => authAxios().delete(`/vehicleModels/${id}`);

export const updateById = (id, data) => authAxios().post(`/vehicleModels/${id}?_method=PUT`, data);
